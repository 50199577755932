import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import colors from '../app/colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: colors.lightTeal,
      main: colors.mediumTeal,
      dark: colors.darkTeal,
      contrastText: colors.lightGray,
    },
  },
})
export default responsiveFontSizes(theme)
