import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Qualification from './Qualification'
import routes from '../../app/routes'
import { getIsAuthenticated, getHasFinishedIntro } from '../user/userSlice'

const Tool = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasFinishedIntro = useSelector(getHasFinishedIntro)

  if (!isAuthenticated) return <Redirect to={routes.registrera} />
  if (hasFinishedIntro) return <Redirect to={routes.resa} />
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingTop: 16, paddingBottom: 16 }}
    >
      <Grid item>
        <Route exact path={routes.skapaVerktyg}>
          <Redirect to={`${routes.skapaVerktyg}/1`} />
        </Route>
        <Route exact path={`${routes.skapaVerktyg}/:number`}>
          <Qualification />
        </Route>
      </Grid>
    </Grid>
  )
}
export default Tool
