import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core'
import {
  StarOutlined,
  FireOutlined,
  RocketOutlined,
  CrownOutlined,
} from '@ant-design/icons'
import {
  getActivitiesById,
  getHasFinishedFirstActivity,
  setActivity,
  saveActivity,
} from './activitySlice'
import { getUsername } from '../user/userSlice'
import PrimaryButton from '../../components/PrimaryButton'
import { Heading, SubHeading, Text } from '../../components/typography'
import colors from '../../app/colors'

const getIcon = () => {
  const style = {
    fontSize: 150,
    color: colors.darkYellow,
  }
  const icons = [
    <StarOutlined style={style} />,
    <FireOutlined style={style} />,
    <RocketOutlined style={style} />,
    <CrownOutlined style={style} />,
  ]

  return icons[Math.floor(Math.random() * icons.length)]
}

const getDialogText = (name) => {
  const dialogTexts = [
    {
      title: 'Heja!',
      body: 'Du har genomfört en aktivitet och är ett steg närmare ditt mål.',
    },
    {
      title: 'Bra jobbat!',
      body: 'Nu har du fixat den här aktiviteten också.',
    },
    {
      title: `Härligt ${name}!`,
      body: 'Du har genomfört en aktivitet och är ett steg närmare ditt mål.',
    },
    {
      title: 'Nemas problemas!',
      body:
        'Du fixar alla aktiviteter som du har bestämt dig för att göra. Snyggt!',
    },
    {
      title: 'Hurra!',
      body: 'Den här aktiviteten är genomförd. Dags för nästa steg!',
    },
    {
      title: 'Snyggt!',
      body: `Du fixar det här, ${name}!`,
    },
    {
      title: 'Javisst!',
      body: 'Nu har du klarat den här aktiviteten. Grymt jobbat!',
    },
    {
      title: `Du är en stjärna, ${name}!`,
      body: 'Den här etappen klarar du galant.',
    },
    {
      title: 'Bravo!',
      body:
        'Ännu en aktivitet är klar. Känner du tyngden släppa från dina axlar?',
    },
    {
      title: `Strålande, ${name}!`,
      body: 'För varje aktivitet kommer du närmare tjänsten du vill ha.',
    },
    {
      title: 'Solklart!',
      body: 'Du följer din plan och kommer närmare drömjobbet.',
    },
    {
      title: `Grattis ${name}!`,
      body: 'Ditt jobbsökande går framåt. Redo för nästa steg?',
    },
    {
      title: 'Super!',
      body: 'Du tar tag i att-göra-listan. På rullande sten växer ingen mossa.',
    },
    {
      title: 'High-five!',
      body: 'Var stolt över din insats på den här etappen!',
    },
  ]
  return dialogTexts[Math.floor(Math.random() * dialogTexts.length)]
}

const ActivityDialog = ({ activityId, saveLink, open, onClose }) => {
  const [comment, setComment] = useState('')
  const dispatch = useDispatch()
  const activity = useSelector(getActivitiesById)[activityId]
  const hasFinishedFirstActivity = useSelector(getHasFinishedFirstActivity)
  const isError = comment.length > 200
  const name = useSelector(getUsername)
  const nameCapitalized =
    name.charAt(0).toUpperCase() + name.slice(1).split(' ', 1)
  const [dialogText, setDialogText] = useState(getDialogText(nameCapitalized))
  const [icon, setIcon] = useState(getIcon())

  const handleCancel = () => {
    dispatch(setActivity({ ...activity, isFinished: false }))
    onClose()
  }

  useEffect(() => {
    if (activity) {
      setComment(activity.comment || '')
    }
  }, [activity])

  useEffect(() => {
    if (activityId) {
      setDialogText(getDialogText(nameCapitalized))
      setIcon(getIcon())
    }
  }, [nameCapitalized, activityId])

  //TODO: Loading state, error handling...
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      onBackdropClick={handleCancel}
      onEscapeKeyDown={handleCancel}
    >
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>{icon}</Grid>
          <Grid item>
            <Heading>{dialogText.title}</Heading>
          </Grid>
          <Grid item>
            <SubHeading id="form-dialog-title" style={{ textAlign: 'center' }}>
              {dialogText.body}
            </SubHeading>
          </Grid>
          <Grid item>
            {!hasFinishedFirstActivity && (
              <Text>
                Reflektera över vad som gick bra och mindre bra. Skriv en
                kommentar och klappa dig själv på axeln. Bra jobbat!
              </Text>
            )}
          </Grid>
          <Grid item style={{ width: '100%', paddingTop: 16 }}>
            <TextField
              fullWidth
              error={isError}
              helperText={isError && 'Max 200 tecken'}
              label="Kommentar"
              placeholder="Vad gick bra / mindre bra? Reflektera här!"
              multiline
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton danger onClick={handleCancel}>
          Ångra
        </PrimaryButton>
        <PrimaryButton
          disabled={isError}
          onClick={() => {
            dispatch(saveActivity({ ...activity, comment }))
            onClose()
          }}
        >
          {saveLink ? (
            <Link to={saveLink}>Spara och fortsätt</Link>
          ) : (
            'Spara och fortsätt'
          )}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export default ActivityDialog
