import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import validator from 'validator'
import { Col, Form, Row } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import Card from '../../components/Card'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'
import PrimaryButton from '../../components/PrimaryButton'
import routes from '../../app/routes'
import { sendResetPasswordLink } from './userSlice'
import { Text } from '../../components/typography'
import ScreenHeader from '../../components/ScreenHeader'
import { setFlashMessage } from '../flashmessage/flashMessageSlice'
import { useDispatch } from 'react-redux'

const ForgottenPassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const sendEmail = async ({ email }) => {
    setIsLoading(true)
    try {
      await sendResetPasswordLink(email)
      dispatch(
        setFlashMessage({
          message:
            'En länk för att återställa ditt lösenord har skickats till din mejladress',
        })
      )
      setIsEmailSent(true)
    } catch (e) {
      setErrorMessage(e.message)
      form.resetFields(['email'])
    }
    setIsLoading(false)
  }

  if (isEmailSent) return <Redirect to={routes.login} />
  return (
    <div className="userWrapper">
      <Row type="flex" justify="center" align="middle">
        <Col>
          <ScreenHeader
            title="Skicka länk"
            subtitle="Återställ lösenord"
            backButtonRoute={routes.login}
          />
          <Card>
            <Text>
              Behöver du återställa ditt lösenord? Fyll i din mejladress, så
              skickar vi en länk.
            </Text>
            <Form
              name="basic"
              onFinish={sendEmail}
              form={form}
              style={{ marginTop: '10px' }}
            >
              <Form.Item
                name="email"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: 'Din mejladress',
                    validateTrigger: 'onSubmit',
                  },
                  () => ({
                    validator(rule, value) {
                      if (validator.isEmail(value)) return Promise.resolve()

                      return Promise.reject(
                        'Den här adressen verkar tyvärr ogiltig'
                      )
                    },
                  }),
                ]}
              >
                <InputField
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  type="email"
                />
              </Form.Item>

              {errorMessage !== '' && <FormError>{errorMessage}</FormError>}

              <Form.Item style={{ textAlign: 'center' }}>
                <PrimaryButton loading={isLoading} htmlType="submit">
                  Skicka länk
                </PrimaryButton>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ForgottenPassword
