import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Grid } from '@material-ui/core'
import Card from '../../components/Card'
import { Text, Heading, SubHeading } from '../../components/typography'
import IconButton from '../../components/IconButton'
import ProgressBar from '../../components/ProgressBar'
import StageDisplay from './../../components/StageDisplay'
import routes from '../../app/routes'
import Status from './Status'
import {
  getQualificationCount,
  getQualificationsById,
  setToolStatus,
  finishToolSelection,
} from './toolSlice'

const Qualification = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const qualificationNumber = +params.number
  const qualificationId = 'v' + qualificationNumber
  const qualificationsById = useSelector(getQualificationsById)
  const currentQualification = qualificationsById[qualificationId]
  const qualificationCount = useSelector(getQualificationCount)
  const disableBackButton = qualificationNumber === 1

  const handleForwardClick = () => {
    // If on last tool status, save tool selections
    if (qualificationNumber === qualificationCount) {
      dispatch(finishToolSelection())
    }
  }

  const handleToolStatusOption = (toolStatus) => {
    dispatch(setToolStatus({ id: qualificationId, toolStatus }))
  }

  let linkForward =
    qualificationNumber < qualificationCount
      ? `${routes.skapaVerktyg}/${qualificationNumber + 1}`
      : routes.valjReseLangd

  if (Number.isNaN(qualificationNumber) || !currentQualification) {
    return <Redirect to={routes.skapaVerktyg} />
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Heading>Granska din verktygslåda</Heading>
      </Grid>
      <Grid item>
        <SubHeading
          style={{ textAlign: 'center', paddingLeft: 8, paddingRight: 8 }}
        >
          Hur ser dina jobbsökarverktyg ut i dag?
        </SubHeading>
      </Grid>
      <Grid item>
        <StageDisplay stage="2" />
      </Grid>
      <Card>
        <Grid container direction="column" alignItems="stretch">
          <div style={{ textAlign: 'center' }}>
            <SubHeading>{currentQualification.title}</SubHeading>
          </div>
          <Text>{currentQualification.description}</Text>
          <Status
            value={currentQualification.toolStatus}
            onChange={(_, v) => handleToolStatusOption(v)}
            bronze={currentQualification.bronze}
            silver={currentQualification.silver}
            gold={currentQualification.gold}
          />
          <Grid container justify="space-around" style={{ marginTop: 16 }}>
            <Grid item>
              <IconButton
                disabled={disableBackButton}
                onClick={() => {
                  history.goBack()
                }}
                shape="circle"
                icon={<ArrowLeftOutlined />}
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleForwardClick}
                shape="circle"
                icon={
                  <Link to={linkForward}>
                    <ArrowRightOutlined />
                  </Link>
                }
              />
            </Grid>
          </Grid>
          <Grid container justify="space-around" style={{ marginTop: 16 }}>
            <ProgressBar
              currentCount={qualificationNumber}
              totalCount={qualificationCount}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

export default Qualification
