import moment from 'moment'
import { createSlice } from '@reduxjs/toolkit'
import fb, {
  changeEmail,
  changePassword,
  resetPassword as resetUserPassword,
  sendResetPasswordLinkByEmail as sendResetPasswordLink,
  userDelete,
  userLogin,
  userLogout,
  userReauthorize,
  userRegistration,
  updateUserData,
  readUserData,
  extractUserData,
  removeRegistrationCode,
  validateRegistrationCode,
  deleteUserData,
} from '../../app/firebase'
import { setToolState } from '../tool/toolSlice'
import { setActivityState, getAllActivityIds } from '../activity/activitySlice'
import {
  getHasFinishedQuiz,
  getToken,
  populateStateFromToken,
} from '../quiz/quizSlice'
import { setReward } from '../reward/rewardSlice'
import { DAYS_PER_SEGMENT } from '../../app/constants'

// Reexported for convenience (keep business logic in redux slice..)
export { sendResetPasswordLink, resetUserPassword, deleteUserData }

const initialState = {
  id: '',
  isAuthenticated: false,
  hasCheckedActiveSession: false,
  hasViewedWelcomeDialog: false,
  name: '',
  email: '',
  journeyLength: 4,
  journeyEndDate: moment()
    .add(4 * DAYS_PER_SEGMENT, 'days')
    .endOf('day')
    .toISOString(),
  userGotNewJob: undefined,
  hasFinishedIntro: false,
}

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    checkedForActiveSession: (state, { payload }) => {
      state.hasCheckedActiveSession = payload
    },
    setActiveSession: (state, { payload }) => {
      const {
        id,
        isAuthenticated,
        name,
        email,
        journeyLength,
        journeyEndDate,
        hasFinishedIntro,
        userGotNewJob,
      } = payload
      state.id = id
      state.isAuthenticated = isAuthenticated
      state.name = name
      state.email = email
      state.journeyLength = journeyLength ?? initialState.journeyLength
      state.hasFinishedIntro = hasFinishedIntro ?? initialState.hasFinishedIntro
      state.journeyEndDate = journeyEndDate ?? initialState.journeyEndDate
      state.userGotNewJob = userGotNewJob ?? initialState.userGotNewJob
    },
    logout: () => {
      return initialState
    },
    setHasViewedWelcomeDialog: (state, { payload }) => {
      state.hasViewedWelcomeDialog = payload
    },
    setJourneyLength: (state, { payload }) => {
      state.journeyLength = payload
      state.journeyEndDate = moment()
        .add(payload * DAYS_PER_SEGMENT, 'days')
        .endOf('day')
        .toISOString()
    },
    setJourneyEndDate: (state, { payload }) => {
      state.journeyEndDate = payload
    },
    setUserGotNewJob: (state, { payload }) => {
      state.userGotNewJob = payload
    },
    setHasFinishedIntro: (state, { payload }) => {
      state.hasFinishedIntro = payload
    },
    setEmail: (state, { payload }) => {
      state.email = payload
    },
  },
})

export const {
  logout,
  setActiveSession,
  checkedForActiveSession,
  setHasViewedWelcomeDialog,
  setJourneyLength,
  setJourneyEndDate,
  setUserGotNewJob,
  setHasFinishedIntro,
  setEmail,
} = slice.actions

export const registerUser = ({
  email,
  firstName,
  surname,
  password,
  registrationCode,
}) => async (dispatch, getState) => {
  const state = getState()
  await validateRegistrationCode(registrationCode)
  let user = await userRegistration(email, password, firstName, surname)
  dispatch(setActiveSession(user))
  if (getHasFinishedQuiz(state)) {
    const token = getToken(state)
    updateUserData({ quizResultToken: token })
  }
  removeRegistrationCode(registrationCode) // No need to await - just slows down registration..
}

export const hasActiveUserSession = () => (dispatch, getState) => {
  const unsubscribe = fb.auth().onAuthStateChanged(
    async (user) => {
      if (user) {
        let userData = extractUserData(user)
        await dispatch(populateReduxStore(userData))
      }
      unsubscribe()
      dispatch(checkedForActiveSession(true))
    },
    (e) => {
      dispatch(checkedForActiveSession(true))
    }
  )
}

export const authenticateUser = ({ email, password, remember }) => async (
  dispatch
) => {
  let user = await userLogin(email, password, remember)
  dispatch(populateReduxStore(user))
}

export const populateReduxStore = (user) => async (dispatch) => {
  const userData = await readUserData()
  const {
    journeyLength,
    hasFinishedIntro,
    journeyEndDate,
    quizResultToken,
    userGotNewJob,
  } = userData ?? {}
  dispatch(
    setActiveSession({
      ...user,
      journeyLength,
      hasFinishedIntro,
      journeyEndDate,
      userGotNewJob,
    })
  )
  if (userData?.tools) {
    dispatch(setToolState({ qualifications: userData.tools }))
  }
  if (userData?.activities) {
    dispatch(setActivityState({ activitiesById: userData.activities }))
  }
  if (userData?.rewards) {
    // Handle strange Firebase behavior - sometimes saved as List/Array, sometimes as Object..
    if (Array.isArray(userData.rewards)) {
      userData.rewards.forEach((reward, i) => {
        if (reward) {
          dispatch(setReward({ segmentNumber: i, reward }))
        }
      })
    } else {
      Object.keys(userData.rewards).forEach((key) => {
        const reward = userData.rewards[key]
        if (reward) {
          dispatch(setReward({ segmentNumber: key, reward }))
        }
      })
    }
  }
  if (quizResultToken) {
    try {
      dispatch(populateStateFromToken(quizResultToken))
    } catch (e) {
      console.error(e)
    }
  }
}

export const logoutUser = () => async (dispatch) => {
  await userLogout()
  dispatch(logout())
}

export const changeUserPassword = async ({
  email,
  currentPassword,
  newPassword,
}) => {
  await userReauthorize(email, currentPassword)
  await changePassword(newPassword)
}

export const changeUserEmail = ({ email, newEmail, password }) => async (
  dispatch
) => {
  await userReauthorize(email, password)
  await changeEmail(newEmail)
  dispatch(setEmail(newEmail))
}

export const deleteUserAccount = (email, password) => async (dispatch) => {
  await userReauthorize(email, password)
  await userDelete()
  dispatch(logout())
}

export const getIsAuthenticated = (state) => state.user.isAuthenticated

export const getHasViewedWelcomeDialog = (state) =>
  state.user.hasViewedWelcomeDialog

export const getHasCheckedActiveSession = (state) =>
  state.user.hasCheckedActiveSession

export const getUserEmail = (state) => state.user.email

export const getUserId = (state) => state.user.id

export const getUsername = (state) => state.user.name

export const getJourneyLength = (state) => state.user.journeyLength

export const getHasFinishedIntro = (state) => state.user.hasFinishedIntro

export const getUserGotNewJob = (state) => state.user.userGotNewJob

export const getJourneyDaysLeft = (state) =>
  moment(state.user.journeyEndDate).diff(moment(), 'days')

export const getCurrentSegment = (state) => {
  if (getJourneyDaysLeft(state) === 0) {
    return state.user.journeyLength
  }
  const segmentsLeft = Math.floor(
    (getJourneyDaysLeft(state) - 1) / DAYS_PER_SEGMENT
  )
  return state.user.journeyLength - segmentsLeft
}

export const getSegmentDaysLeft = (state) => {
  return moment(state.user.journeyEndDate)
    .subtract(
      (state.user.journeyLength - getCurrentSegment(state)) * DAYS_PER_SEGMENT,
      'days'
    )
    .diff(moment(), 'days')
}

export const getIsUserOnScheduleForSegment = (state) => {
  const segmentNumber = getCurrentSegment(state)
  const nrOfActivitiesLeftInSegment = getAllActivityIds(state)
    .map((id) => state.activity.activitiesById[id])
    .filter(
      (activity) =>
        activity.segmentNumber === segmentNumber && !activity.isFinished
    ).length
  const daysLeftOnSegment = getSegmentDaysLeft(state)
  return Math.ceil(daysLeftOnSegment / 6) >= nrOfActivitiesLeftInSegment
}

export const finishIntro = () => async (dispatch, getState) => {
  dispatch(setHasFinishedIntro(true))
  const { journeyLength, journeyEndDate, hasFinishedIntro } = getState().user
  updateUserData({ journeyLength, journeyEndDate, hasFinishedIntro })
}

export const changeUserGotNewJob = (payload) => async (dispatch) => {
  dispatch(setUserGotNewJob(payload))
  updateUserData({ userGotNewJob: payload })
}

export const changeJourneyEndDate = () => async (dispatch, getState) => {
  const state = getState()
  const newJourneyEndDate = moment(state.user.journeyEndDate)
    .subtract(getSegmentDaysLeft(state), 'days')
    .toISOString()
  dispatch(setJourneyEndDate(newJourneyEndDate))
  updateUserData({ journeyEndDate: newJourneyEndDate })
}

export default slice.reducer
