import React, { useState, useEffect } from 'react'
import styles from './NumberBox.module.css'
import clsx from 'clsx'

const NumberBox = ({
  type = 'primary',
  round,
  disabled,
  number,
  positionLeft,
  positionTop,
  icon,
  delayedIconTimer,
}) => {
  const [iconOpacity, setIconOpacity] = useState(0)
  let delayTimeouts = []

  useEffect(() => {
    let isMounted = true // prevents issues with timeouts when component gets unmounted

    const delayedIconDraw = (delayTime) => {
      return setTimeout(() => {
        if (isMounted) {
          setIconOpacity(1)
        }
      }, delayTime)
    }

    if (delayedIconTimer) {
      delayTimeouts.push(delayedIconDraw(delayedIconTimer))
    }
    return () => {
      // clear timeouts on unmount
      isMounted = false
      delayTimeouts.forEach((timeout) => {
        clearTimeout(timeout)
      })
    }
  })

  return (
    <div
      id={`box${number}`}
      className={clsx(
        styles.box,
        `box${number}`,
        type === 'primary' && styles.boxPrimary,
        type === 'light' && styles.boxLight,
        round && styles.boxRound,
        disabled && styles.boxDisabled
      )}
      style={{
        left: positionLeft,
        top: positionTop,
      }}
      key={number}
    >
      {icon ? (
        <div
          style={{
            position: 'absolute',
            top: -20,
            right: -15,
            margin: 0,
            padding: 0,
            transition: 'opacity 0.7s',
            opacity: delayedIconTimer ? iconOpacity : 1,
          }}
        >
          {icon}
        </div>
      ) : null}
      <p className={clsx(styles.text)} type="dark">
        {number}
      </p>
    </div>
  )
}

export default NumberBox
