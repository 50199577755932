import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from '@material-ui/core'
import {
  TrophyOutlined,
  CrownOutlined,
  CheckCircleFilled,
} from '@ant-design/icons'
import { getJourneyLength, getUsername } from '../user/userSlice'
import { getRewardsBySegment } from '../reward/rewardSlice'
import PrimaryButton from '../../components/PrimaryButton'
import { Heading, SubHeading, Text } from '../../components/typography'
import colors from '../../app/colors'
import routes from '../../app/routes'

const SegmentFinishDialog = ({ segmentNumber, open, onClose }) => {
  const journeyLength = useSelector(getJourneyLength)
  const reward = useSelector(getRewardsBySegment)[segmentNumber]
  const name = useSelector(getUsername)
  const nameCapitalized =
    name.charAt(0).toUpperCase() + name.slice(1).split(' ', 1)

  const isLastSegment = journeyLength === segmentNumber

  let message =
    'Bara ' + (journeyLength - segmentNumber) + ' etapper kvar till mål!'

  if (segmentNumber + 1 === journeyLength) {
    message = 'Nu är du snart i mål – nästa etapp är den sista!'
  }

  if (isLastSegment) {
    message =
      'Du är nu klar med hela din resa och vi hoppas att du fått ut ett värde av appen. I nästa steg får du en överblick av alla aktiviteter du genomfört under din resa.'
  }

  const iconStyle = {
    fontSize: 150,
    color: colors.darkYellow,
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="segment-finish-dialog"
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            {isLastSegment ? (
              <CrownOutlined style={iconStyle} />
            ) : (
              <TrophyOutlined style={iconStyle} />
            )}
          </Grid>
          <Grid item>
            <Heading>{`Grattis${
              isLastSegment ? ' ' + nameCapitalized : ''
            }!`}</Heading>
          </Grid>
          <Grid item>
            <SubHeading
              id="segment-finish-dialog"
              style={{ textAlign: 'center' }}
            >
              {isLastSegment
                ? 'Du har gått jobbstigen från början till slut. Du har fixat alla etapper och har genomfört en massa aktiviteter på vägen. Starkt jobbat!'
                : `Du är klar med etapp ${segmentNumber}.`}
            </SubHeading>
          </Grid>
          <Grid item>
            <Text>{message}</Text>
          </Grid>
          <Grid
            item
            style={{ marginTop: 8, marginBottom: 8, alignSelf: 'stretch' }}
          >
            <Divider />
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <CheckCircleFilled
                style={{
                  fontSize: 18,
                  color: colors.mediumGreen,
                  marginRight: 8,
                }}
              />
              <SubHeading>Din belöning</SubHeading>
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <Text>Kom ihåg att belöna dig själv.</Text>
            <Text
              style={{
                fontWeight: 'bold',
                border: '1px solid',
                borderColor: colors.mediumGray,
                borderRadius: 4,
                marginTop: 16,
                padding: 8,
              }}
            >
              {reward}
            </Text>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          onClick={() => {
            onClose()
          }}
        >
          <Link
            to={
              isLastSegment
                ? routes.resa
                : `${routes.resa}/${segmentNumber + 1}`
            }
          >
            {isLastSegment ? 'Färdig' : 'Nästa etapp'}
          </Link>
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export default SegmentFinishDialog
