import React from 'react'
import { useSelector } from 'react-redux'
import { Progress } from 'antd'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Grid, Box } from '@material-ui/core'
import {
  getCurrentSegment,
  getJourneyDaysLeft,
  getJourneyLength,
} from '../../../features/user/userSlice'
import { getFinishedActivityCount } from '../../../features/activity/activitySlice'
import { MAX_ACTIVITY_COUNT } from '../../../app/constants'
import { Text, Heading } from '../../../components/typography'
import RewardHeader from '../../../features/reward/RewardHeader'
import { DAYS_PER_SEGMENT } from '../../../app/constants'
import colors from '../../../app/colors'

export const useStyles = makeStyles(() => ({
  appBar: {
    webkitBoxShadow: 'none',
    mozBoxShadow: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    textAlign: 'center',
  },
  grid: {
    paddingTop: 5,
  },
  boxPhone: {
    paddingTop: '5px',
    margin: 'auto',
  },
  text: {
    textAlign: 'center',
    fontFamily: 'Fredoka One',
    fontSize: '15px',
    color: colors.darkGray,
  },
  typography: {
    fontFamily: 'Fredoka One',
    color: colors.darkGray,
  },
}))

const PhoneMainAppbar = () => {
  const classes = useStyles()
  const journeyDaysLeft = useSelector(getJourneyDaysLeft)
  const currentSegment = useSelector(getCurrentSegment)
  const journeyLength = useSelector(getJourneyLength)

  const finishedActivityCount = useSelector((state) =>
    getFinishedActivityCount(state, currentSegment)
  )

  return (
    <AppBar className={classes.appBar}>
      <Box className={classes.boxPhone}>
        <Heading className={classes.typography}>Min resa</Heading>
      </Box>
      <Toolbar>
        <Grid container className={classes.grid}>
          <Grid item xs={4}>
            <RewardHeader height={70} />
            <Text className={classes.text}>Dina belöningar</Text>
          </Grid>
          <Grid item xs={4}>
            <Progress
              type="circle"
              percent={(finishedActivityCount / MAX_ACTIVITY_COUNT) * 100}
              format={() => `${currentSegment} av ${journeyLength}`}
              strokeWidth={12}
              width={70}
              strokeColor={colors.mediumAmber}
              trailColor={colors.transparentGrey}
              status="normal"
            />
            <Text className={classes.text}>Etapp</Text>
          </Grid>
          <Grid item xs={4}>
            <Progress
              type="circle"
              percent={
                (1 - journeyDaysLeft / (DAYS_PER_SEGMENT * journeyLength)) * 100
              }
              format={() => (
                <div>
                  {journeyDaysLeft} <br />
                  dagar
                </div>
              )}
              strokeWidth={12}
              width={70}
              strokeColor={colors.mediumAmber}
              trailColor={colors.transparentGrey}
              status="normal"
            />
            <Text className={classes.text}>Slutmål</Text>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default PhoneMainAppbar
