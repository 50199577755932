import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSegmentDaysLeft } from '../../../features/user/userSlice'
import { getFinishedActivityCount } from '../../../features/activity/activitySlice'
import { Heading } from '../../../components/typography'
import { Progress } from 'antd'
import colors from '../../../app/colors'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Rating from '@material-ui/lab/Rating'
import { withStyles } from '@material-ui/core/styles'
import { DAYS_PER_SEGMENT } from '../../../app/constants'

export const useStyles = makeStyles(() => ({
  appBar: {
    webkitBoxShadow: 'none',
    mozBoxShadow: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    textAlign: 'center',
  },
  boxPhone: {
    paddingTop: '5px',
    margin: 'auto',
  },
  text: {
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Fredoka One',
    color: colors.darkGray,
  },
  biggerStars: {
    fontSize: '35px',
    paddingTop: '16px',
    paddingBottom: '18px',
  },
}))

const StyledRating = withStyles({
  iconFilled: {
    color: colors.mediumAmber,
  },
  iconEmpty: {
    color: colors.transparentGrey,
  },
})(Rating)

// TODO Gör etappmål specefikt för varje delresa (Om användaren vill kolla på en tidigare delresa ska etappmål progress visa 100% complete)
const PhoneSegmentAppbar = () => {
  const classes = useStyles()
  const segmentDaysLeft = useSelector(getSegmentDaysLeft)
  let { segmentNumber } = useParams()
  segmentNumber = parseInt(segmentNumber)

  const finishedActivityCount = useSelector((state) =>
    getFinishedActivityCount(state, segmentNumber)
  )

  return (
    <Box>
      <AppBar className={classes.appBar}>
        <Box className={classes.boxPhone}>
          <Heading>Etapp {segmentNumber}</Heading>
        </Box>
        <Toolbar>
          <Box className={classes.boxPhone}>
            <Progress
              type="circle"
              percent={(1 - segmentDaysLeft / DAYS_PER_SEGMENT) * 100}
              format={() => (
                <div>
                  {segmentDaysLeft}
                  <br />
                  dagar
                </div>
              )}
              strokeWidth={12}
              width={70}
              strokeColor={colors.mediumAmber}
              trailColor={colors.transparentGrey}
              status="normal"
            />
            <Heading className={classes.text}>Etappmål</Heading>
          </Box>
          <Box className={classes.boxPhone}>
            <StyledRating
              className={classes.biggerStars}
              name="customized-color"
              readOnly
              value={finishedActivityCount}
            />
            <Heading className={classes.text}>Aktiviteter</Heading>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default PhoneSegmentAppbar
