import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Empty, Tabs } from 'antd'
import { Chip, Grid, List, Avatar } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { CheckCircleOutlined } from '@ant-design/icons'
import {
  getAllActivityIds,
  getActivitiesById,
  getFinishedActivityCount,
  setActivity,
} from './activitySlice'
import { getCurrentSegment } from '../user/userSlice'
import ActivityDialog from './ActivityDialog'
import Card from '../../components/Card'
import { Text } from '../../components/typography'
import Button from '../../components/PrimaryButton'
import colors from '../../app/colors'
import routes from '../../app/routes'

const { TabPane } = Tabs

const ActivityList = ({ segmentNumber }) => {
  const [dialogId, setDialogId] = useState(null)
  const dispatch = useDispatch()
  const allActivityIds = useSelector(getAllActivityIds)
  const activitiesById = useSelector(getActivitiesById)
  const finishedActivityCount = useSelector((state) =>
    getFinishedActivityCount(state, segmentNumber)
  )
  const currentSegment = useSelector(getCurrentSegment)

  const activities = allActivityIds
    .map((id) => activitiesById[id])
    .filter((activity) =>
      segmentNumber ? activity.segmentNumber === segmentNumber : activity
    )

  const handleClick = (activityId) => {
    const activity = activitiesById[activityId]
    dispatch(
      setActivity({
        ...activity,
        isFinished: true,
      })
    )
    setDialogId(activity.id)
  }

  //TODO: Sort in some specific way?
  return (
    <Grid container direction="column" alignItems="center">
      {activities.length ? (
        <List>
          {activities
            .sort((a, b) =>
              !segmentNumber ? (a.isFinished ? -1 : 1) : a.isFinished ? 1 : -1
            )
            .map((activity, index) => {
              return (
                <Card
                  key={activity.id}
                  style={{
                    position: 'relative',
                    marginBottom: 16,
                    paddingBottom: 16,
                    borderTop: '6px solid',
                    borderColor: activity.isFinished
                      ? colors.mediumGreen
                      : colors.mediumRed,
                  }}
                >
                  {activity.isFinished && (
                    <Avatar
                      style={{
                        position: 'absolute',
                        left: -10,
                        top: -16,
                        border: '2px solid white',
                        backgroundColor: colors.mediumGreen,
                      }}
                    >
                      <Check />
                    </Avatar>
                  )}
                  {!segmentNumber && (
                    <Chip
                      label={'Etapp ' + activity.segmentNumber}
                      style={{ position: 'absolute', right: 8, top: 8 }}
                      size="small"
                      variant="outlined"
                      color="primary"
                      clickable
                      component={Link}
                      to={`${routes.resa}/${activity.segmentNumber}/aktiviteter`}
                    />
                  )}
                  <Text
                    style={{ fontWeight: 'bold' }}
                    id={'aktivitet-' + activity.id}
                  >
                    {activity.title}
                  </Text>
                  <Tabs
                    defaultActiveKey={
                      activity.isFinished && activity.comment ? '2' : '1'
                    }
                    style={{ minHeight: 130 }}
                  >
                    <TabPane tab="Beskrivning" key="1">
                      {activity.description}
                    </TabPane>
                    <TabPane tab="Kommentar" key="2">
                      {activity.comment ||
                        'Ingen kommentar' +
                          (activity.isFinished ? '' : ' än...')}
                    </TabPane>
                  </Tabs>
                  {!activity.isFinished && segmentNumber === currentSegment && (
                    <Button
                      block
                      onClick={() => handleClick(activity.id)}
                      style={{
                        marginTop: 16,
                        marginBottom: 8,
                      }}
                      icon={<CheckCircleOutlined />}
                    >
                      Markera som klar
                    </Button>
                  )}
                </Card>
              )
            })}
        </List>
      ) : (
        <Card>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Du har inte valt några aktiviteter än.."
          />
        </Card>
      )}
      <ActivityDialog
        saveLink={
          segmentNumber &&
          finishedActivityCount === 5 &&
          `${routes.resa}/${segmentNumber}/aktiviteter/klar`
        }
        activityId={dialogId}
        open={!!dialogId}
        onClose={() => setDialogId(null)}
      />
    </Grid>
  )
}

export default ActivityList
