import React from 'react'
import styles from './FormError.module.css'

const FormError = ({ children, ...props }) => {
  return (
    <div className={styles.formError} {...props}>
      {children}
    </div>
  )
}

export default FormError
