import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import { Col, Form, Row } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Heading } from '../../components/typography'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'
import PrimaryButton from '../../components/PrimaryButton'
import { changeUserEmail, getUserEmail } from './userSlice'
import { setFlashMessage } from '../flashmessage/flashMessageSlice'

const ChangeEmail = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const userEmail = useSelector(getUserEmail)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const changeEmail = async (values) => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      values.email = userEmail
      await dispatch(changeUserEmail(values))
      form.resetFields(['newEmail', 'password'])
      dispatch(setFlashMessage({ message: 'Din mejladress är uppdaterad' }))
    } catch (e) {
      form.resetFields(['newEmail', 'password'])
      setErrorMessage(e.message)
    }
    removeMessages()
    setIsLoading(false)
  }

  const removeMessages = () => {
    setTimeout(() => {
      setErrorMessage('')
    }, 3000)
  }

  return (
    <Row type="flex" justify="" align="middle" className="userWrapper">
      <Col flex={1}>
        <Heading type="primary" align="center">
          Byt email
        </Heading>

        <Form name="changeEmailForm" onFinish={changeEmail} form={form}>
          <Form.Item
            name="newEmail"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Din nya mejladress',
                validateTrigger: 'onSubmit',
              },
              {
                validator(rule, value) {
                  if (validator.isEmail(value)) return Promise.resolve()
                  return Promise.reject(
                    'Den här adressen verkar tyvärr ogiltig'
                  )
                },
              },
            ]}
          >
            <InputField
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Ny email"
              type="email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Ditt lösenord',
                validateTrigger: 'onSubmit',
              },
              () => ({
                validator(rule, value) {
                  if (value.length >= 6) return Promise.resolve()

                  return Promise.reject('Ditt lösenord är minst 6 tecken långt')
                },
              }),
            ]}
          >
            <InputField
              password={true}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Lösenord"
            />
          </Form.Item>

          {errorMessage !== '' && <FormError>{errorMessage}</FormError>}

          <Form.Item style={{ textAlign: 'center' }}>
            <PrimaryButton loading={isLoading} htmlType="submit">
              Byt mejladress
            </PrimaryButton>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default ChangeEmail
