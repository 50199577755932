/**
 * Main routes config. Use this object to avoid string dependencies in internal routing.
 */
export default {
  home: '/',
  quiz: '/test',
  intro: '/intro',
  registrera: '/intro/registrera',
  valkommen: '/intro/valkommen',
  skapaVerktyg: '/intro/skapaVerktyg',
  valjReseLangd: '/intro/valjReseLangd',
  login: '/login',
  konto: '/konto',
  glomtLosenord: '/glomtLosenord',
  resa: '/resa',
  verktyg: '/verktyg',
  aktiviteter: '/aktiviteter',
  avslutning: '/avslutning',
  personuppgifter: '/personuppgifter',
}
