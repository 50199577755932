// TODO: Add SDKs for the Firebase products we want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import axios from 'axios'

const firebaseConfig = {
  apiKey: 'AIzaSyB-aqy8iWgrtRx5x25w24rKTShnvPeEp5E',
  authDomain: 'jobbstigen.firebaseapp.com',
  databaseURL: 'https://jobbstigen.firebaseio.com',
  projectId: 'jobbstigen',
  storageBucket: 'jobbstigen.appspot.com',
  messagingSenderId: '963864987454',
  appId: '1:963864987454:web:1454ad0283fa3ba20f0e6f',
}

// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig)

// Initialize Realtime Database
export const db = firebase.database()

// Initialize Auth Service
const auth = fb.auth()

export const userRegistration = async (email, password, firstName, surname) => {
  try {
    auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    await auth.createUserWithEmailAndPassword(email, password)
    let user = auth.currentUser
    await user.updateProfile({
      displayName: `${firstName} ${surname}`,
    })
    return extractUserData(user)
  } catch (e) {
    if (e.code === 'auth/email-already-in-use') {
      throw new Error(
        'Det finns redan ett konto registrerat med den här mejladressen.'
      )
    }
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const validateRegistrationCode = async (regCode) => {
  let invalidRegistrationCode =
    'Den registreringskod du angett verkar inte finnas i vår databas.'
  try {
    let res = await axios.get(
      `https://us-central1-jobbstigen.cloudfunctions.net/validateRegistrationCode?code=${regCode}`
    )
    const isRegistrationCodeValid = res.data.payload
    if (!isRegistrationCodeValid) throw new Error(invalidRegistrationCode)
  } catch (e) {
    if (e.message === invalidRegistrationCode)
      throw new Error(invalidRegistrationCode)
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const removeRegistrationCode = async (regCode) => {
  try {
    await axios.delete(
      `https://us-central1-jobbstigen.cloudfunctions.net/removeRegistrationCode?code=${regCode}`
    )
  } catch (e) {
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const userLogin = async (email, password, remember) => {
  try {
    if (remember) {
      auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    } else {
      auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    }
    let res = await auth.signInWithEmailAndPassword(email, password)
    const { user } = res
    return extractUserData(user)
  } catch (e) {
    if (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found')
      throw new Error('Felaktig mejladress eller lösenord')

    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const userLogout = async () => {
  try {
    return await auth.signOut()
  } catch (e) {
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const userReauthorize = async (email, password) => {
  try {
    const user = auth.currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    )
    await user.reauthenticateWithCredential(credential)
  } catch (e) {
    if (e.code === 'auth/wrong-password') throw new Error('Felaktigt lösenord')

    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const changePassword = async (newPassword) => {
  try {
    const user = auth.currentUser
    await user.updatePassword(newPassword)
  } catch (e) {
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const changeEmail = async (newEmail) => {
  try {
    let isEmailTaken = await auth.fetchSignInMethodsForEmail(newEmail)
    if (isEmailTaken.length > 0)
      throw new Error(
        'Det finns redan ett konto registrerat med den här mejladressen'
      )
    const user = auth.currentUser
    await user.updateEmail(newEmail)
  } catch (e) {
    if (
      e.message ===
      'Det finns redan ett konto registrerat med den här mejladressen'
    )
      throw new Error(
        'Det finns redan ett konto registrerat med den här mejladressen'
      )
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const userDelete = async () => {
  try {
    const authUser = auth.currentUser
    const dbUser = db.ref('users/' + authUser.uid)
    await dbUser.remove()
    await authUser.delete()
  } catch (e) {
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const sendResetPasswordLinkByEmail = async (email) => {
  try {
    auth.sendPasswordResetEmail(email)
  } catch (e) {
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export const resetPassword = async (code, password) => {
  try {
    await auth.confirmPasswordReset(code, password)
  } catch (e) {
    if (e.code === 'auth/invalid-action-code')
      throw new Error(
        'Återställningslänken är ogiltig, skicka ett nytt återställningsmejl till din mejl och försök igen'
      )
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

export async function readUserData() {
  const data = await db.ref('users/' + auth.currentUser.uid).once('value')
  return data.val()
}

export function updateUserData(data, path = '') {
  db.ref('users/' + auth.currentUser.uid + path).update(data)
}

export const deleteUserData = async () => {
  try {
    const authUser = auth.currentUser
    const dbUser = db.ref('users/' + authUser.uid)
    await dbUser.remove()
  } catch (e) {
    throw new Error('Oväntat fel på servern, var god försök igen')
  }
}

// Helper functions
export const extractUserData = (user) => {
  return {
    id: user.uid,
    isAuthenticated: true,
    name: user.displayName === null ? '' : user.displayName,
    email: user.email,
  }
}

export default fb
