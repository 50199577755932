import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { Text, Heading } from '../../components/typography'
import PrimaryButton from '../../components/PrimaryButton'
import { SubHeading } from '../../components/typography/SubHeading'
import Card from '../../components/Card'
import routes from '../../app/routes'
import {
  getUsername,
  getIsAuthenticated,
  getHasFinishedIntro,
} from './userSlice'

const Welcome = () => {
  const name = useSelector(getUsername)
  const nameCapitalized =
    name.charAt(0).toUpperCase() + name.slice(1).split(' ', 1)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasFinishedIntro = useSelector(getHasFinishedIntro)

  if (!isAuthenticated) return <Redirect to={routes.registrera} />
  if (hasFinishedIntro) return <Redirect to={routes.resa} />

  const styles = {
    li: { fontSize: '15px', marginLeft: '-20px' },
    button: { margin: 'auto' },
    heading: { textAlign: 'center' },
  }

  const steps = [
    'Välj när du vill nå ditt mål.',
    'Dela in ditt jobbsökande i etapper.',
    'Använd professionella jobbsökarverktyg.',
    'Arbeta med konkreta jobbsökaraktiviteter.',
    'Få en belöning efter varje aktivitet.',
  ]

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingTop: 16, paddingBottom: '40px' }}
    >
      <Grid item>
        <Heading style={{ textAlign: 'center', marginBottom: 8 }}>
          Hej {nameCapitalized}!
        </Heading>
      </Grid>
      <Card>
        <Grid container direction="column" alignItems="center">
          <SubHeading style={{ textAlign: 'center' }}>
            Nytt jobb – från tanke till handling
          </SubHeading>
          <Grid container style={{ marginTop: 16 }}>
            <Grid item>
              <Text style={{ fontWeight: 'bold' }}>
                Den här appen hjälper dig till ditt nya jobb, steg för steg.
              </Text>
              <br />
              <ul>
                {steps.map((item) => (
                  <li style={styles.li} key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item>
              <SubHeading>
                Välkommen ombord. Trevlig resa!
                <br />
                <br />
              </SubHeading>
            </Grid>
            <br />
            <Grid item style={styles.button}>
              <PrimaryButton color="primary">
                <Link to={`${routes.skapaVerktyg}/1`}>Mina verktyg</Link>
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

export default Welcome
