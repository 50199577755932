import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setFlashMessageError } from '../features/flashmessage/flashMessageSlice'
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core'
import PrimaryButton from '../components/PrimaryButton'
import { Heading, SubHeading, Text } from '../components/typography'
import colors from '../app/colors'
import { AlertOutlined } from '@ant-design/icons'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { isError: false, open: true }
    this.onClose = this.onClose.bind(this)
  }

  static getDerivedStateFromError(error) {
    return { isError: true }
  }

  onClose() {
    window.location.replace('/')
  }

  render() {
    if (this.state.isError) {
      return (
        <Dialog
          open={this.state.open}
          onClose={this.onClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth
          onBackdropClick={this.onClose}
          onEscapeKeyDown={this.onClose}
        >
          <DialogContent>
            <Grid container direction="column" alignItems="center" spacing={1}>
              <Grid item>
                <AlertOutlined
                  style={{
                    fontSize: 150,
                    color: colors.darkRed,
                  }}
                />
              </Grid>
              <Grid item>
                <Heading style={{ textAlign: 'center' }}>
                  Något gick visst fel...
                </Heading>
              </Grid>
              <Grid item>
                <SubHeading
                  id="form-dialog-title"
                  style={{ textAlign: 'center' }}
                >
                  Felet kommer från vår sida.
                </SubHeading>
              </Grid>
              <Grid item>
                <Text>
                  Snart kan du fortsätta använda Jobbstigen precis som tidigare.
                  Inom några sekunder kommer du kunna klicka på "Tillbaka till
                  Jobbstigen" för att återvända till applikationen.
                </Text>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <PrimaryButton type="primary" onClick={this.onClose}>
              Tillbaka till Jobbstigen
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )
    }
    return this.props.children
  }
}

export default connect(null, { setFlashMessageError })(ErrorBoundary)
