import React from 'react'
import clsx from 'clsx'
import styles from './SubHeading.module.css'

const SubHeading = ({ type, shadow, children, ...props }) => (
  <h2
    className={clsx(
      styles.subHeading,
      type === 'primary' && styles.subHeadingPrimary,
      type === 'light' && styles.subHeadingLight,
      type === 'dark' && styles.subHeadingDark,
      shadow && styles.shadow
    )}
    {...props}
  >
    {children}
  </h2>
)

export { SubHeading }
