import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFlashMessage,
  clearFlashMessage,
  getErrorStatus,
} from './flashMessageSlice'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import colors from '../../app/colors'

const SUCCESS_FADE_TIMEOUT = 7000
const ERROR_FADE_TIMEOUT = 30000

const FlashMessage = () => {
  const message = useSelector(getFlashMessage)
  const isError = useSelector(getErrorStatus)
  const dispatch = useDispatch()

  function closeFlashMessage() {
    dispatch(clearFlashMessage())
  }

  return (
    <div>
      <Snackbar
        open={message.length > 0}
        autoHideDuration={isError ? ERROR_FADE_TIMEOUT : SUCCESS_FADE_TIMEOUT}
        onClose={closeFlashMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={closeFlashMessage}
          severity={isError ? 'error' : 'success'}
          style={{
            backgroundColor: isError ? colors.mediumRed : colors.mediumGreen,
            minWidth: '300px',
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default FlashMessage
