import React from 'react'
import { Avatar } from '@material-ui/core'
import { Check } from '@material-ui/icons/'
import colors from '../app/colors'

const CheckAvatar = () => (
  <Avatar
    style={{
      zIndex: 999,
      backgroundColor: colors.darkGreen,
      border: '1px solid white',
      width: 30,
      height: 30,
    }}
  >
    <Check />
  </Avatar>
)

export default CheckAvatar
