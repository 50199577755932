import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Progress } from 'antd'
import { Grid, Typography } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  getSegmentDaysLeft,
  getUsername,
} from '../../../features/user/userSlice'
import { getFinishedActivityCount } from '../../../features/activity/activitySlice'
import { DAYS_PER_SEGMENT } from '../../../app/constants'
import colors from '../../../app/colors'

const useStyles = makeStyles((theme) => ({
  root: { margin: '0', padding: '0', border: '0' },
  iconButton: {
    marginRight: theme.spacing(7),
    '&:hover': { backgroundColor: 'transparent' },
  },
  typography: {
    fontFamily: 'Fredoka One',
    color: colors.darkGray,
  },
  progressTitle: {
    fontFamily: 'Fredoka One',
    textAlign: 'center',
    color: colors.darkGray,
  },
  biggerStars: {
    fontSize: '60px',
    paddingTop: '16px',
    paddingBottom: '24px',
  },
  container: {
    padding: '12px',
  },
}))

const StyledRating = withStyles({
  iconFilled: {
    color: colors.mediumAmber,
  },
  iconEmpty: {
    color: colors.transparentGrey,
  },
})(Rating)

const DesktopSegmentAppbar = () => {
  const classes = useStyles()
  const name = useSelector(getUsername)
  const nameCapitalized =
    name.charAt(0).toUpperCase() + name.slice(1).split(' ', 1)
  const segmentDaysLeft = useSelector(getSegmentDaysLeft)
  let { segmentNumber } = useParams()
  segmentNumber = parseInt(segmentNumber)

  const finishedActivityCount = useSelector((state) =>
    getFinishedActivityCount(state, segmentNumber)
  )

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classes.container}
      >
        <Grid item>
          <Grid style={{ float: 'left' }}>
            <Grid style={{ float: 'right', marginLeft: '100px' }}>
              <Typography className={classes.typography} variant="h4">
                Etapp {segmentNumber}
              </Typography>
              <Typography className={classes.typography} variant="h5">
                Hej {nameCapitalized}!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginLeft: '-214' }}>
          <Grid style={{ float: 'left', marginRight: '5px' }}>
            <Progress
              type="circle"
              percent={(1 - segmentDaysLeft / DAYS_PER_SEGMENT) * 100}
              format={() => (
                <div>
                  {segmentDaysLeft}
                  <br />
                  dagar
                </div>
              )}
              strokeWidth={12}
              width={100}
              strokeColor={colors.mediumAmber}
              trailColor={colors.transparentGrey}
              status="normal"
            />
            <Typography variant="h5" className={classes.progressTitle}>
              Etappmål
            </Typography>
          </Grid>
          <Grid style={{ float: 'right', marginLeft: '5px' }}>
            <StyledRating
              className={classes.biggerStars}
              name="customized-color"
              readOnly
              value={finishedActivityCount}
            />
            <Typography variant="h5" className={classes.progressTitle}>
              Aktiviteter
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid></Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default DesktopSegmentAppbar
