import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import {
  getHasCheckedActiveSession,
  getIsAuthenticated,
  hasActiveUserSession,
} from './userSlice'

const CheckActiveSession = ({ children }) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasCheckedActiveSession = useSelector(getHasCheckedActiveSession)

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(hasActiveUserSession())
    }
  }, [dispatch, isAuthenticated])

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 150, color: 'white' }} spin />
  )

  if (isAuthenticated || hasCheckedActiveSession) {
    return <>{children}</>
  } else {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin indicator={antIcon} />{' '}
      </div>
    )
  }
}

export default CheckActiveSession
