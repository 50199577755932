import { createSlice } from '@reduxjs/toolkit'
import { updateUserData } from '../../app/firebase'
import { getFinishedActivityCount } from '../activity/activitySlice'

export const slice = createSlice({
  name: 'reward',
  initialState: {
    rewardsBySegment: {},
  },
  reducers: {
    setReward: (state, { payload }) => {
      const { segmentNumber, reward } = payload
      state.rewardsBySegment[segmentNumber] = reward
    },
  },
})

export const { setReward } = slice.actions

export const getRewardsBySegment = (state) => state.reward.rewardsBySegment

export const getFinishedActivitiesBySegment = (state) => {
  let finishedActivitiesBySegment = {}
  for (let i = 1; i <= state.user.journeyLength; i++) {
    finishedActivitiesBySegment[i] = getFinishedActivityCount(state, i) === 5
  }
  return finishedActivitiesBySegment
}

export const saveReward = ({ segmentNumber, reward }) => async (dispatch) => {
  dispatch(setReward({ segmentNumber, reward }))
  updateUserData({ [segmentNumber]: reward }, '/rewards')
}

export default slice.reducer
