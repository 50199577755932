import React, { useState, useEffect, useRef } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import RoomIcon from '@material-ui/icons/Room'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  getJourneyLength,
  getCurrentSegment,
  getJourneyDaysLeft,
  getIsAuthenticated,
  getHasViewedWelcomeDialog,
  getHasFinishedIntro,
} from '../user/userSlice'
import LeaderLine from './LeaderLine'
import NumberBox from './NumberBox'
import MainAppbarWrapper from '../../components/appbar/MainAppbarWrapper'
import CheckAvatar from '../../components/CheckAvatar'
import routes from '../../app/routes'
import colors from '../../app/colors'
import { TABLET_SCREEN_LIMIT } from '../../app/constants'

const MainJourney = () => {
  const [responsiveBoxes, setBoxes] = useState([])
  const [leaderLines, setLeaderLines] = useState()

  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasFinishedIntro = useSelector(getHasFinishedIntro)
  const journeyLength = useSelector(getJourneyLength)
  const currentSegment = useSelector(getCurrentSegment)
  const journeyDaysLeft = useSelector(getJourneyDaysLeft)
  const hasViewedWelcomeDialog = useSelector(getHasViewedWelcomeDialog)

  const isMobileOrTabletWidth = useMediaQuery(
    `(max-width:${TABLET_SCREEN_LIMIT})`
  )
  const isAnimationFinished = useRef(hasViewedWelcomeDialog)

  useEffect(() => {
    let isMounted = true // prevents issues with timeouts when component gets unmounted
    let delayTimeouts = []

    const createJourneyBoxes = (isAnimation) => {
      if (isMounted) {
        let finalPositions
        if (journeyLength === 6) {
          isMobileOrTabletWidth
            ? (finalPositions = mobileMediumStepPositions)
            : (finalPositions = mediumStepPositions)
        } else if (journeyLength === 8) {
          isMobileOrTabletWidth
            ? (finalPositions = mobileLongStepPositions)
            : (finalPositions = longStepPositions)
        } else {
          isMobileOrTabletWidth
            ? (finalPositions = mobileShortStepPositions)
            : (finalPositions = shortStepPositions)
        }

        let numberedBoxes = []
        for (let i = journeyLength; i > 0; i--) {
          if (i > currentSegment) {
            numberedBoxes.push(
              <NumberBox
                key={'box-' + i}
                positionLeft={finalPositions[i - 1].left}
                positionTop={finalPositions[i - 1].top}
                number={i}
                disabled
              />
            )
          } else {
            numberedBoxes.push(
              <Link to={`${routes.resa}/${i}`} key={i}>
                <NumberBox
                  positionLeft={finalPositions[i - 1].left}
                  positionTop={finalPositions[i - 1].top}
                  number={i}
                  delayedIconTimer={isAnimation ? i * 900 - 700 : null}
                  icon={
                    hasViewedWelcomeDialog ? (
                      i === currentSegment ? (
                        <RoomIcon
                          style={{
                            color: colors.mediumAmber,
                            width: 40,
                            height: 40,
                            marginTop: -10,
                          }}
                        />
                      ) : (
                        <CheckAvatar />
                      )
                    ) : null
                  }
                />
              </Link>
            )
          }
        }

        return numberedBoxes
      }
    }

    const createLeaderlines = (
      isAnimation,
      isHidden = false,
      drawDelay = 0
    ) => {
      return setTimeout(() => {
        if (isMounted) {
          setLeaderLines(
            <LeaderLine
              key={100}
              classId="box"
              mainColor={'rgb(76, 220, 80, 0.7)'}
              numberOfLines={journeyLength}
              numOfFullOpacityLines={currentSegment}
              animation={isAnimation}
              hideLines={isHidden}
            />
          )
        }
      }, drawDelay)
    }

    if (hasViewedWelcomeDialog) {
      // animation on first render of component
      if (!isAnimationFinished.current) {
        setBoxes(createJourneyBoxes(true))
        delayTimeouts.push(createLeaderlines(true, false, 200))
        isAnimationFinished.current = true
      } else {
        setBoxes(createJourneyBoxes(false))
        delayTimeouts.push(createLeaderlines(false))
      }
    }

    // Redraws lines on resize
    let timeout = null
    const resizeListener = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        createLeaderlines(false)
      }, 150)
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      // clear timeouts and listeners on unmount
      isMounted = false
      delayTimeouts.forEach((timeout) => {
        clearTimeout(timeout)
      })
      window.removeEventListener('resize', resizeListener)
    }
  }, [
    currentSegment,
    isMobileOrTabletWidth,
    journeyLength,
    hasViewedWelcomeDialog,
  ])

  if (!isAuthenticated) return <Redirect to={routes.home} />
  if (!hasFinishedIntro) return <Redirect to={routes.valkommen} />
  if (journeyDaysLeft <= 0) return <Redirect to={routes.avslutning} />

  return (
    <div>
      <MainAppbarWrapper />
      {responsiveBoxes}
      {leaderLines}
    </div>
  )
}

export default MainJourney

let shortStepPositions = [
  { left: '55%', top: '85%' },
  { left: '46%', top: '60%' },
  { left: '43%', top: '40%' },
  { left: '49.5%', top: '30.5%' },
]

let mediumStepPositions = [
  { left: '55%', top: '85%' },
  { left: '40%', top: '70%' },
  { left: '53%', top: '57%' },
  { left: '47%', top: '47%' },
  { left: '41%', top: '38%' },
  { left: '52%', top: '30.5%' },
]

let longStepPositions = [
  { left: '53%', top: '89%' },
  { left: '58%', top: '82%' },
  { left: '45%', top: '77%' },
  { left: '58%', top: '66%' },
  { left: '50%', top: '55%' },
  { left: '47%', top: '45%' },
  { left: '42%', top: '38%' },
  { left: '49.5%', top: '30.5%' },
]

let mobileShortStepPositions = [
  { left: '59%', top: '85%' },
  { left: '50%', top: '55%' },
  { left: '25%', top: '40%' },
  { left: '50%', top: '33%' },
]

let mobileMediumStepPositions = [
  { left: '55%', top: '83%' },
  { left: '30%', top: '66%' },
  { left: '60%', top: '57%' },
  { left: '40%', top: '43%' },
  { left: '12%', top: '40%' },
  { left: '50%', top: '33%' },
]

let mobileLongStepPositions = [
  { left: '55%', top: '86%' },
  { left: '78%', top: '78%' },
  { left: '28%', top: '72%' },
  { left: '65%', top: '62%' },
  { left: '46%', top: '53%' },
  { left: '33%', top: '43%' },
  { left: '7%', top: '40%' },
  { left: '50%', top: '33%' },
]
