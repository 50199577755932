import React from 'react'
import { Input } from 'antd'
import styles from './InputField.module.css'

const InputField = ({ password = false, ...props }) => {
  if (password) {
    return <Input.Password className={styles.inputField} {...props} />
  } else {
    return <Input className={styles.inputField} {...props} />
  }
}

export default InputField
