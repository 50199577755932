import { createSlice } from '@reduxjs/toolkit'
import { updateUserData } from '../../app/firebase'
import questions from './quiz.json'
import colors from '../../app/colors'

export const POINT_RANGE = {
  start: 1,
  end: 5,
  midpoint: 3,
}

export const SCORE_MAP = {
  1: {
    scoreTag: 'Stämmer mycket dåligt',
    weightTag: 'Frågan är helt oviktig',
    satisfactionTag: 'mycket missnöjd',
    color: colors.darkRed,
  },
  2: {
    scoreTag: 'Stämmer ganska dåligt',
    weightTag: 'Frågan är ganska oviktig',
    satisfactionTag: 'ganska missnöjd',
    color: colors.mediumRed,
  },
  3: {
    scoreTag: 'Stämmer någorlunda',
    weightTag: 'Ingen åsikt',
    satisfactionTag: 'varken nöjd eller missnöjd',
    color: colors.mediumTeal,
  },
  4: {
    scoreTag: 'Stämmer ganska bra',
    weightTag: 'Frågan är ganska viktig',
    satisfactionTag: 'ganska nöjd',
    color: colors.mediumGreen,
  },
  5: {
    scoreTag: 'Stämmer mycket bra',
    weightTag: 'Frågan är mycket viktig',
    satisfactionTag: 'mycket nöjd',
    color: colors.darkGreen,
  },
}

const questionsByNumber = {}
const allQuestionNumbers = []

questions.forEach((q, i) => {
  const number = i + 1
  questionsByNumber[number] = {
    title: q.section,
    question: q.question,
    importance: q.importance,
    score: POINT_RANGE.midpoint,
    weight: POINT_RANGE.midpoint,
    isAnswered: false,
  }
  allQuestionNumbers.push(number)
})

export const slice = createSlice({
  name: 'quiz',
  initialState: {
    questionsByNumber,
    allQuestionNumbers,
  },
  reducers: {
    setScore: (state, { payload }) => {
      const { number, score } = payload
      state.questionsByNumber[number].score = score
    },
    setWeight: (state, { payload }) => {
      const { number, weight } = payload
      state.questionsByNumber[number].weight = weight
      state.questionsByNumber[number].isAnswered = true
    },
    answerQuestion: (state, { payload }) => {
      state.questionsByNumber[payload].isAnswered = true
    },
    setQuizState: (_, { payload }) => payload,
  },
})

export const {
  setScore,
  setWeight,
  answerQuestion,
  setQuizState,
} = slice.actions

export const getQuestionsByNumber = (state) => state.quiz.questionsByNumber
export const getAllQuestionNumbers = (state) => state.quiz.allQuestionNumbers
export const getHasFinishedQuiz = (state) => {
  let hasFinished = true
  state.quiz.allQuestionNumbers.forEach((number) => {
    if (!state.quiz.questionsByNumber[number].isAnswered) {
      hasFinished = false
    }
  })
  return hasFinished
}
export const getQuestionCount = (state) => state.quiz.allQuestionNumbers.length
export const getQuizResult = (state) => {
  const { allQuestionNumbers, questionsByNumber } = state.quiz
  const maxScore = POINT_RANGE.end * getQuestionCount(state)
  const minScore = POINT_RANGE.start * getQuestionCount(state)
  let userScore = 0
  allQuestionNumbers.forEach((number) => {
    const { score, weight } = questionsByNumber[number]
    if (score < POINT_RANGE.midpoint && weight < POINT_RANGE.midpoint) {
      userScore += POINT_RANGE.midpoint // If negative answer but unimportant, give midpoint score.
    } else {
      userScore += score
    }
  })
  let calculatedScore = (userScore - minScore) / (maxScore - minScore)
  if (calculatedScore < 0) {
    calculatedScore = 0
  }
  return Math.round(calculatedScore * 100) + '%'
}

export const getCategoryPoints = (state) =>
  state.quiz.allQuestionNumbers
    .reduce((acc, currNumber) => {
      const question = state.quiz.questionsByNumber[currNumber]
      const foundCategory = acc.find((el) => el.category === question.title)
      if (foundCategory) {
        foundCategory.weightPoints += question.weight
        foundCategory.scorePoints += question.score
        foundCategory.totalPoints += POINT_RANGE.end
      } else {
        acc.push({
          category: question.title,
          scorePoints: question.score,
          weightPoints: question.weight,
          totalPoints: POINT_RANGE.end,
        })
      }
      return acc
    }, [])
    .map((cat) => ({
      category: cat.category,
      pointScore:
        Math.round((cat.scorePoints / cat.totalPoints) * POINT_RANGE.end * 10) /
        10,
      weightScore:
        Math.round(
          (cat.weightPoints / cat.totalPoints) * POINT_RANGE.end * 10
        ) / 10,
    }))
    .sort((a, b) => b.weightScore - a.weightScore)

export const getToken = (state) => {
  const tokenString = state.quiz.allQuestionNumbers
    .map((number) => {
      const question = state.quiz.questionsByNumber[number]
      return (
        question.score.toString().padStart(2, 0) +
        question.weight.toString().padStart(2, 0)
      )
    })
    .join('-')
  return tokenString
}

export const saveTokenToDb = (token) =>
  updateUserData({ quizResultToken: token })

export const populateStateFromToken = (token) => (dispatch) => {
  const validateParsedNumber = (number) => {
    if (
      isNaN(number) ||
      number < POINT_RANGE.start ||
      number > POINT_RANGE.end
    ) {
      throw new Error('Din länk var tyvärr inte giltig')
    }
  }

  const answersFromToken = token.split('-').map((answer) => {
    const score = parseInt(answer.substring(0, 2))
    const weight = parseInt(answer.substring(2, 4))
    validateParsedNumber(score)
    validateParsedNumber(weight)
    return {
      score,
      weight,
    }
  })

  if (answersFromToken.length !== allQuestionNumbers.length) {
    throw new Error('Din länk var tyvärr inte giltig')
  }

  const newQuestionsByNumber = {}
  answersFromToken.forEach((answer, i) => {
    const number = i + 1
    newQuestionsByNumber[number] = {
      ...questionsByNumber[number],
      isAnswered: true,
      score: answer.score,
      weight: answer.weight,
    }
  })
  dispatch(
    setQuizState({
      questionsByNumber: newQuestionsByNumber,
      allQuestionNumbers,
    })
  )
}

export const resetQuiz = () => (dispatch) =>
  dispatch(
    setQuizState({
      questionsByNumber,
      allQuestionNumbers,
    })
  )

export default slice.reducer
