import React from 'react'
import { useSelector } from 'react-redux'
import { TrophyFilled } from '@ant-design/icons'
import { Grid, Tooltip } from '@material-ui/core'
import {
  getRewardsBySegment,
  getFinishedActivitiesBySegment,
} from './rewardSlice'
import { getJourneyLength, getCurrentSegment } from '../user/userSlice'
import colors from '../../app/colors'

const RewardHeader = ({ height = 100 }) => {
  const journeyLength = useSelector(getJourneyLength)
  const rewardsBySegment = useSelector(getRewardsBySegment)
  const currentSegment = useSelector(getCurrentSegment)
  const finishedActivitiesBySegment = useSelector(
    getFinishedActivitiesBySegment
  )
  let rewards = []
  for (let i = 1; i <= journeyLength; i++) {
    const reward = rewardsBySegment[i]
    const didGetReward = finishedActivitiesBySegment[i]
    let rewardTitle = reward
    if (reward && !didGetReward && i !== currentSegment) {
      rewardTitle = 'Missad belöning: ' + reward
    }
    rewards.push({
      title: rewardTitle || 'Ingen belöning vald ännu',
      didGetReward,
    })
  }

  return (
    <Grid container justify="center" alignItems="center" style={{ height }}>
      {rewards.map((reward, index) => (
        <Grid item key={index}>
          <Tooltip arrow enterTouchDelay={0} title={reward.title}>
            <TrophyFilled
              style={{
                fontSize: 28,
                color: reward.didGetReward
                  ? colors.mediumAmber
                  : colors.transparentGrey,
              }}
            />
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  )
}

export default RewardHeader
