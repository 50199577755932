import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
} from '@material-ui/core'
import Card from '../../components/Card'
import { TealRadio } from '../../components/radios'
import { Text, Heading, SubHeading } from '../../components/typography'
import PrimaryButton from '../../components/PrimaryButton'
import StageDisplay from '../../components/StageDisplay'
import {
  getIsAuthenticated,
  getHasFinishedIntro,
  getJourneyLength,
  setJourneyLength,
  finishIntro,
} from '../user/userSlice'
import routes from '../../app/routes'

const JourneyLengthChooser = () => {
  const dispatch = useDispatch()
  const journeyLength = useSelector(getJourneyLength)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasFinishedIntro = useSelector(getHasFinishedIntro)

  const handleChange = (event) => {
    dispatch(setJourneyLength(parseInt(event.target.value)))
  }

  const onClick = () => {
    dispatch(finishIntro())
  }

  if (!isAuthenticated) return <Redirect to={routes.registrera} />
  if (hasFinishedIntro) return <Redirect to={routes.resa} />
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingTop: 16 }}
    >
      <Grid item>
        <Heading style={{ textAlign: 'center' }}>Tid</Heading>
        <StageDisplay stage="3" />
      </Grid>
      <Grid item>
        <Card>
          <SubHeading>Hur många månader ska din resa ta?</SubHeading>
          <FormControl component="fieldset">
            <Text>
              Välj om du ska få ditt nya jobb om fyra, sex eller åtta månader.
            </Text>
            <RadioGroup
              name="reselangd"
              value={journeyLength}
              onChange={handleChange}
            >
              <FormControlLabel
                value={4}
                control={<TealRadio />}
                label="4 månader"
              />
              <FormControlLabel
                value={6}
                control={<TealRadio />}
                label="6 månader"
              />
              <FormControlLabel
                value={8}
                control={<TealRadio />}
                label="8 månader"
              />
            </RadioGroup>
          </FormControl>
          <br />
          <PrimaryButton
            style={{ marginLeft: 60, marginRight: 60 }}
            onClick={onClick}
          >
            <Link to={routes.resa}>Starta resa</Link>
          </PrimaryButton>
        </Card>
      </Grid>
    </Grid>
  )
}

export default JourneyLengthChooser
