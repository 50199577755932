import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Form, Modal } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { Text } from '../../components/typography'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'
import PrimaryButton from '../../components/PrimaryButton'
import routes from '../../app/routes'
import {
  deleteUserAccount,
  getIsAuthenticated,
  getUserEmail,
} from './userSlice'

const DeleteAccount = ({ ...props }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const userEmail = useSelector(getUserEmail)
  const [errorMessage, setErrorMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const displayModal = (e) => {
    e.preventDefault()
    setShowModal(true)
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setShowModal(false)
  }

  const deleteAccount = async ({ password }) => {
    setIsLoading(true)
    try {
      await dispatch(deleteUserAccount(userEmail, password))
      setShowModal(false)
    } catch (e) {
      setErrorMessage(e.message)
    }
    form.resetFields(['password'])
    removeErrorMessage()
    setIsLoading(false)
  }

  const removeErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('')
    }, 3000)
  }

  if (!isAuthenticated) return <Redirect to={routes.home} />

  return (
    <div>
      <PrimaryButton {...props} danger={true} onClick={displayModal}>
        Radera konto
      </PrimaryButton>
      <Modal
        title="Bekräfta att du vill radera ditt konto"
        visible={showModal}
        onOk={deleteAccount}
        onCancel={handleCancel}
        footer={[
          <PrimaryButton key="back" onClick={handleCancel}>
            Avbryt
          </PrimaryButton>,
          <PrimaryButton
            key="submit"
            form="deleteUserAccountForm"
            htmlType="submit"
            loading={isLoading}
            onClick={() => {
              form.validateFields().then((values) => {
                deleteAccount(values)
              })
            }}
            danger={true}
          >
            Radera konto
          </PrimaryButton>,
        ]}
      >
        <Text>
          När du väl raderat ditt konto går det inte att återställa. Vänligen
          ange ditt lösenord för att radera ditt konto
        </Text>
        <Form id="deleteUserAccountForm" form={form}>
          <Form.Item
            name="password"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Ditt lösenord',
                validateTrigger: 'onSubmit',
              },
              () => ({
                validator(rule, value) {
                  if (value.length >= 6) return Promise.resolve()

                  return Promise.reject(
                    'Ditt lösenord är 6 tecken eller längre'
                  )
                },
              }),
            ]}
          >
            <InputField
              password={true}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Lösenord"
            />
          </Form.Item>
          {errorMessage !== '' && <FormError>{errorMessage}</FormError>}
        </Form>
      </Modal>
    </div>
  )
}

export default DeleteAccount
