import React from 'react'
import { Col, Row } from 'antd'
import Card from './Card'
import { Text } from './typography'
import ScreenHeader from './ScreenHeader'

const GDPR = () => {
  return (
    <div className="userWrapper">
      <ScreenHeader
        title="Personuppgifter"
        subtitle="Behandling av dina personuppgifter i Jobbstigen"
      />
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Card>
            <Text
              style={{ fontSize: 14, lineHeight: 1.2, textJustify: 'justify' }}
            >
              <strong>Verto Konsult AB</strong> (”Verto”),{' '}
              <a href="https://verto.se/">https://verto.se/</a>, är
              personuppgiftsansvarig för behandling av dina personuppgifter i
              Jobbstigen. Verto behandlar dina personuppgifter (i huvudsak namn,
              e-postadress och den information du delgett oss i inloggat läge) i
              syfte att hjälpa dig på din resa att hitta ett nytt jobb och för
              att administrera ditt konto. Behandlingen sker i syfte att
              fullgöra ett avtal med dig. För oss är din integritet viktig och
              vi sprider inte vidare dina kontaktuppgifter till tredje part,
              såvida du själv inte väljer att dela med dig av dem. Vi överför
              inte personuppgifter utanför EU. Vi lagrar dina personuppgifter så
              länge du själv är aktiv i appen. Du kan själv välja att radera
              alla dina uppgifter genom att radera ditt konto. Du har rätt att
              begära tillgång till personuppgifterna, begära om rättelse,
              radering eller begränsning av behandlingen, du har rätt att
              invända mot behandlingen av personuppgifter och slutligen har du
              rätt till så kallad dataportabilitet. Du har alltid rätt att lämna
              in ett klagomål till Datainspektionen över vår behandling av
              personuppgifter. Om du har några frågor ber vi dig kontakta oss så
              kan vi berätta mer om vår behandling.
            </Text>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default GDPR
