import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import clsx from 'clsx'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import Card from '../../components/Card'
import { Heading, SubHeading, Text } from '../../components/typography'
import PrimaryButton from '../../components/PrimaryButton'
import styles from './JourneyFinisher.module.css'
import { getAllActivityIds, getActivitiesById } from '../activity/activitySlice'
import {
  changeUserGotNewJob,
  getUserGotNewJob,
  getJourneyDaysLeft,
  deleteUserData,
  logoutUser,
} from '../user/userSlice'
import {
  setFlashMessage,
  setFlashMessageError,
} from '../flashmessage/flashMessageSlice'
import DeleteAccount from '../user/DeleteAccount'
import routes from '../../app/routes'
import colors from '../../app/colors'

const JourneyFinisher = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const userGotNewJob = useSelector(getUserGotNewJob)
  const allActivityIds = useSelector(getAllActivityIds)
  const activitiesById = useSelector(getActivitiesById)
  const journeyDaysLeft = useSelector(getJourneyDaysLeft)

  const activities = allActivityIds.map((id) => activitiesById[id])
  const finishedActivities = activities.filter(
    (activity) => activity.isFinished
  )

  const onYesClick = () => {
    dispatch(changeUserGotNewJob(true))
  }

  const onNoClick = () => {
    dispatch(changeUserGotNewJob(false))
  }

  const onLogout = async () => {
    await dispatch(logoutUser())
    dispatch(
      setFlashMessage({
        message: 'Du har blivit utloggad. Stäng din webbläsare.',
      })
    )
    history.push(routes.home)
  }

  const onRestartClick = async () => {
    try {
      setLoading(true)
      await deleteUserData()
      window.location.reload()
    } catch (e) {
      dispatch(setFlashMessageError(e))
    }
    setLoading(false)
  }

  if (journeyDaysLeft > 0) return <Redirect to={routes.resa} />

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        style={{ maxWidth: '280px', textAlign: 'center', marginTop: 16 }}
      >
        <Heading type="light">Din resa är klar</Heading>
        <SubHeading type="light">Det här har du fixat på vägen:</SubHeading>
      </Grid>
      <br />
      <Grid item>
        <Card style={{ padding: 0 }}>
          <List>
            {finishedActivities.map((activity, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: colors.mediumGreen }}>
                    {index + 1}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={activity.title} />
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>
      <br />
      <Grid item style={{ marginBottom: 32 }}>
        <Card>
          <SubHeading
            type="primary"
            style={{ textAlign: 'center', marginBottom: '10px' }}
          >
            Har du fått nytt jobb?
          </SubHeading>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-around"
          >
            <Grid item>
              <PrimaryButton
                className={clsx(
                  styles.button,
                  styles.yesButton,
                  userGotNewJob === true && styles.yesSelected
                )}
                onClick={onYesClick}
              >
                Ja
              </PrimaryButton>
            </Grid>
            <Grid item>
              <PrimaryButton
                className={clsx(
                  styles.button,
                  styles.noButton,
                  userGotNewJob === false && styles.noSelected
                )}
                onClick={onNoClick}
              >
                Nej
              </PrimaryButton>
              {}
            </Grid>
          </Grid>
          {userGotNewJob !== undefined && (
            <>
              <Grid item>
                <SubHeading
                  type="primary"
                  style={{ textAlign: 'center', marginTop: '10px' }}
                >
                  {userGotNewJob ? 'Du har fått ett nytt jobb!' : 'Kämpa på!'}
                </SubHeading>
                <Text>
                  {userGotNewJob
                    ? 'Vad roligt att höra! Stort grattis till dig – och till din nya arbetsgivare! Du kommer med kompetenser och erfarenheter som verkligen behövs. Lycka till i ditt nya arbete!'
                    : 'Tråkigt att höra att jobbstigen inte tog dig till ditt mål, men vi hoppas att du inspirerades på vägen. Du har lagt tid och energi på ditt jobbsökande – och vi är övertygade om att du är på rätt väg. Varje etapp har slipat dina professionella jobbsökarverktyg, stärkt ditt kontaktnät och rustat dig för nya möjligheter. Var stolt över din insats!'}
                </Text>
              </Grid>
              <PrimaryButton
                block
                onClick={onRestartClick}
                loading={loading}
                style={{ marginTop: 16, marginBottom: 8 }}
              >
                Gör en ny resa
              </PrimaryButton>
              <PrimaryButton
                block
                color="dark"
                onClick={onLogout}
                style={{ marginBottom: 8 }}
              >
                Logga ut
              </PrimaryButton>
              <DeleteAccount block />
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

export default JourneyFinisher
