import React from 'react'
import clsx from 'clsx'
import styles from './Text.module.css'

const Text = ({ type, shadow, children, ...props }) => (
  <p
    className={clsx(
      styles.text,
      type === 'primary' && styles.textPrimary,
      type === 'light' && styles.textLight,
      type === 'dark' && styles.textDark,
      shadow && styles.shadow
    )}
    {...props}
  >
    {children}
  </p>
)

export { Text }
