import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import validator from 'validator'
import { Checkbox, Col, Form, Row } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import Card from '../../components/Card'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'
import PrimaryButton from '../../components/PrimaryButton'
import ScreenHeader from '../../components/ScreenHeader'
import routes from '../../app/routes'
import { authenticateUser, getIsAuthenticated } from './userSlice'

const Login = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const login = async (values) => {
    setIsLoading(true)
    try {
      await dispatch(authenticateUser(values))
    } catch (e) {
      setErrorMessage(e.message)
    }
    setIsLoading(false)
  }

  if (isAuthenticated) return <Redirect to={routes.resa} />
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ marginTop: '5%' }}
    >
      <ScreenHeader title="Logga in" backButtonRoute={routes.home} />
      <Col>
        <Card>
          <Form
            name="loginForm"
            onFinish={login}
            initialValues={{ remember: true }}
            form={form}
          >
            <Form.Item
              name="email"
              validateFirst={true}
              rules={[
                {
                  required: true,
                  message: 'Din mejladress',
                  validateTrigger: 'onSubmit',
                },
                {
                  validator(rule, value) {
                    if (validator.isEmail(value)) return Promise.resolve()
                    return Promise.reject(
                      'Den här adressen verkar tyvärr ogiltig'
                    )
                  },
                },
              ]}
            >
              <InputField
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
                type="email"
                noValidate
              />
            </Form.Item>

            <Form.Item
              name="password"
              validateFirst={true}
              rules={[
                {
                  required: true,
                  message: 'Ditt lösenord',
                  validateTrigger: 'onSubmit',
                },
                {
                  validator(rule, value) {
                    if (value.length >= 6) return Promise.resolve()
                    return Promise.reject(
                      'Ditt lösenord är 6 tecken eller längre'
                    )
                  },
                },
              ]}
            >
              <InputField
                password={true}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Lösenord"
              />
            </Form.Item>

            {errorMessage !== '' && <FormError>{errorMessage}</FormError>}

            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Kom ihåg mig</Checkbox>
            </Form.Item>

            <Link className="tealLink" to={routes.glomtLosenord}>
              Glömt lösenord
            </Link>

            <Form.Item style={{ textAlign: 'center', marginBottom: 8 }}>
              <PrimaryButton
                loading={isLoading}
                htmlType="submit"
                style={{ marginTop: '25px' }}
              >
                Till min resa
              </PrimaryButton>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default Login
