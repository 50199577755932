import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CaretDownOutlined } from '@ant-design/icons'
import {
  Grid,
  FormControl,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  withStyles,
} from '@material-ui/core'
import Card from '../../components/Card'
import { SubHeading, Text } from '../../components/typography'
import ScreenHeader from '../../components/ScreenHeader'
import Status from './Status'
import {
  getQualificationsById,
  setToolStatus,
  finishToolSelection,
} from './toolSlice'

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
  },
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiExpansionPanelDetails)

const ToolList = () => {
  const dispatch = useDispatch()

  const qualificationsObject = useSelector(getQualificationsById)
  const qualifications = Object.values(qualificationsObject)

  const onToolStatusChange = (toolStatus, index) => {
    const qualificationId = 'v' + (index + 1)
    dispatch(setToolStatus({ id: qualificationId, toolStatus }))
    dispatch(finishToolSelection())
  }

  return (
    <>
      <ScreenHeader title="Mina verktyg" subtitle="Hantera dina verktyg" />
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Card>
            {qualifications.map((qualification, index) => {
              return (
                <FormControl
                  key={index}
                  component="fieldset"
                  style={{ display: 'block' }}
                >
                  <ExpansionPanel key={index}>
                    <ExpansionPanelSummary expandIcon={<CaretDownOutlined />}>
                      <SubHeading>{qualification.title}</SubHeading>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        <Text>{qualification.description}</Text>
                        <Status
                          value={qualification.toolStatus}
                          onChange={(_, toolStatus) =>
                            onToolStatusChange(toolStatus, index)
                          }
                          bronze={qualification.bronze}
                          silver={qualification.silver}
                          gold={qualification.gold}
                        />
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </FormControl>
              )
            })}
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default ToolList
