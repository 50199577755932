import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core'
import {
  HistoryOutlined,
  ScheduleOutlined,
  CompassOutlined,
} from '@ant-design/icons'

import PrimaryButton from '../../components/PrimaryButton'
import { Heading, SubHeading, Text } from '../../components/typography'
import colors from '../../app/colors'
import {
  getIsAuthenticated,
  getIsUserOnScheduleForSegment,
  getHasFinishedIntro,
  getHasViewedWelcomeDialog,
  setHasViewedWelcomeDialog,
} from './userSlice'
import {
  getActivityCount,
  getUserMissedLastSegment,
} from '../activity/activitySlice'

const onSchedule = {
  icon: (
    <ScheduleOutlined
      style={{
        fontSize: 150,
        color: colors.darkGreen,
      }}
    />
  ),
  heading: 'Välkommen tillbaka!',
  subHeading: 'Du håller din tidsplan, bra jobbat!',
  text: 'Fortsätt i samma tempo, så når du snart ditt mål.',
}
const notOnSchedule = {
  icon: (
    <HistoryOutlined
      style={{
        fontSize: 150,
        color: colors.darkRed,
      }}
    />
  ),
  heading: 'Välkommen tillbaka!',
  subHeading: 'Du verkar ligga lite efter i din planering...',
  text:
    'Försök hinna med alla fem aktiviteter innan etappen tar slut för att få din belöning. Nu är det dags att sätta lite fart för att komma ikapp! Kom igen, nu kör vi!',
}
const noActivities = {
  icon: <CompassOutlined style={{ fontSize: 150, color: colors.darkGreen }} />,
  heading: 'Nu kör vi!',
  text: 'Klicka på ”1” på kartan och börja resan mot ditt mål.',
}

const missedLastSegment = {
  icon: <CompassOutlined style={{ fontSize: 150, color: colors.darkGreen }} />,
  heading: 'Dags för en ny etapp!',
  text:
    'Ny etapp, nya tag! Du kom inte riktigt i mål med alla aktiviteter i förra etappen. Dags att välja fem nya aktiviteter i denna etapp och en belöning som motiverar dig att klara av dem alla!',
}

const WelcomeDialog = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasFinishedIntro = useSelector(getHasFinishedIntro)
  const hasViewedWelcomeDialog = useSelector(getHasViewedWelcomeDialog)
  const activityCount = useSelector(getActivityCount)
  const isUserOnScheduleForSegment = useSelector(getIsUserOnScheduleForSegment)
  const usermissedLastSegment = useSelector(getUserMissedLastSegment)
  const [open, setOpen] = useState(true)
  let dialog

  if (activityCount === 0) {
    dialog = noActivities
  } else if (usermissedLastSegment) {
    dialog = missedLastSegment
  } else if (isUserOnScheduleForSegment) {
    dialog = onSchedule
  } else {
    dialog = notOnSchedule
  }

  const onClose = () => {
    dispatch(setHasViewedWelcomeDialog(true))
    setOpen(!open)
  }

  if (!isAuthenticated || !hasFinishedIntro || hasViewedWelcomeDialog)
    return null
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      onBackdropClick={onClose}
      onEscapeKeyDown={onClose}
    >
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>{dialog.icon}</Grid>
          <Grid item>
            <Heading style={{ textAlign: 'center' }}>{dialog.heading}</Heading>
          </Grid>
          <Grid item>
            <SubHeading id="form-dialog-title" style={{ textAlign: 'center' }}>
              {dialog.subHeading}
            </SubHeading>
          </Grid>
          <Grid item>
            <Text>{dialog.text}</Text>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton type="primary" onClick={onClose}>
          Till min resa
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export default WelcomeDialog
