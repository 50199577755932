import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useParams } from 'react-router-dom'
import { Alert } from 'antd'
import { FrownTwoTone, MehTwoTone, SmileTwoTone } from '@ant-design/icons'
import { Grid, Divider } from '@material-ui/core'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import Card from '../../components/Card'
import { Text, Heading, SubHeading } from '../../components/typography'
import Button from '../../components/PrimaryButton'
import routes from '../../app/routes'
import colors from '../../app/colors'
import ChartCard from './ChartCard'
import {
  getAllQuestionNumbers,
  getCategoryPoints,
  getHasFinishedQuiz,
  getQuestionsByNumber,
  getQuizResult,
  getToken,
  saveTokenToDb,
  POINT_RANGE as range,
  populateStateFromToken,
  SCORE_MAP as scoreMap,
} from './quizSlice'
import { getIsAuthenticated } from '../user/userSlice'

const Result = () => {
  const [error, setError] = useState()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const questionsByNumber = useSelector(getQuestionsByNumber)
  const allQuestionNumbers = useSelector(getAllQuestionNumbers)
  const generatedToken = useSelector(getToken)
  const existingToken = useParams().token
  const result = useSelector(getQuizResult)
  const hasFinishedQuiz = useSelector(getHasFinishedQuiz)
  const categoryPoints = useSelector(getCategoryPoints)

  useEffect(() => {
    if (existingToken) {
      try {
        dispatch(populateStateFromToken(existingToken))
      } catch (e) {
        setError(e.message)
      }
    }
  }, [existingToken, dispatch])

  const shareUrl = window.location.href
  const shareTitle = `Jag är ${result} nöjd med mitt senaste jobb`
  const shareBody = 'Kolla mitt resultat och gör testet själv här'

  if (!existingToken) {
    if (isAuthenticated) {
      saveTokenToDb(generatedToken)
    }
    return (
      <Redirect
        to={
          hasFinishedQuiz
            ? `${routes.quiz}/resultat/${generatedToken}`
            : routes.quiz
        }
      />
    )
  }

  if (error) {
    return (
      <div>
        <Alert type="error" message={error} banner />
        <Button color="dark" block style={{ marginTop: 32 }}>
          <Link to={routes.quiz}>Gör om testet</Link>
        </Button>
      </div>
    )
  }

  const getEmoji = (score) => {
    score = Math.round(score)
    const color = scoreMap[score].color
    const style = { fontSize: 28, display: 'block', marginTop: 4 }
    let emoji = <SmileTwoTone twoToneColor={color} style={style} />
    if (score < 3) {
      emoji = <FrownTwoTone twoToneColor={color} style={style} />
    }
    if (score === 3) {
      emoji = <MehTwoTone twoToneColor={color} style={style} />
    }
    return emoji
  }

  // creates different sections for charts depending on question weight
  const chartSections = allQuestionNumbers
    .reduce(
      (acc, currNumber) => {
        const question = questionsByNumber[currNumber]
        let categoryIndex = 0
        if (question.weight === range.midpoint) {
          categoryIndex = 1
        }
        if (question.weight < range.midpoint) {
          categoryIndex = 2
        }
        acc[categoryIndex].questions.push(question)
        acc[categoryIndex].questions.sort((a, b) => b.score - a.score)
        return acc
      },
      [
        {
          heading: 'Dina viktigaste frågor',
          questions: [],
        },
        {
          heading: 'Dina neutrala svar',
          questions: [],
        },
        {
          heading: 'Dina minst viktiga frågor',
          questions: [],
        },
      ]
    )
    .filter((section) => section.questions.length > 0)

  return (
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      <Grid item>
        <Card>
          <Grid container direction="column" alignItems="center">
            <SubHeading style={{ margin: 0 }}>Du är</SubHeading>
            <Heading type="primary" style={{ margin: 0 }}>
              {result}
            </Heading>
            <SubHeading>nöjd med ditt jobb</SubHeading>
            {categoryPoints.map((category, i) => (
              <div key={category.category} style={{ marginTop: 16 }}>
                <SubHeading
                  type="primary"
                  style={{ fontSize: 18, textAlign: 'center' }}
                >
                  {category.category}
                </SubHeading>
                <Text style={{ marginBottom: 16 }}>
                  Så här känner du för katagorin:
                  {getEmoji(category.pointScore)}
                </Text>
                <Text>
                  Så här viktig är frågan för dig:
                  <span
                    style={{
                      display: 'block',
                      fontSize: 20,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: colors.mediumTeal,
                    }}
                  >
                    {category.weightScore.toLocaleString('sv-SE')}/{range.end}
                  </span>
                </Text>
                <Divider style={{ marginTop: 8 }} />
              </div>
            ))}
            <Text
              type="primary"
              style={{ fontWeight: 'bold', margin: 8, textAlign: 'center' }}
            >
              Vill du dela resultatet?
            </Text>
            <Grid
              container
              justify="space-around"
              spacing={1}
              style={{ marginBottom: 10 }}
            >
              <Grid item>
                <EmailShareButton
                  url={shareUrl}
                  subject={shareTitle}
                  body={shareBody}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </Grid>
              <Grid item>
                <FacebookShareButton url={shareUrl} quote={shareTitle}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Grid>
              <Grid item>
                <LinkedinShareButton
                  url={shareUrl}
                  title={shareTitle}
                  summary={shareBody}
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Grid>
              <Grid item>
                <TwitterShareButton url={shareUrl} title={shareTitle}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Grid>
            </Grid>
            {!isAuthenticated && (
              <Button block style={{ marginBottom: 16 }}>
                <Link to={routes.registrera}>Skapa konto</Link>
              </Button>
            )}
            <Button color="dark" block>
              <Link to={routes.quiz}>Gör om testet</Link>
            </Button>
          </Grid>
        </Card>
      </Grid>
      {chartSections.map((section) => (
        <Grid item key={section.heading}>
          <ChartCard heading={section.heading} questions={section.questions} />
        </Grid>
      ))}
      {!isAuthenticated && (
        <Grid item>
          <Card>
            <Grid container direction="column" alignItems="center">
              <Heading type="primary" style={{ fontSize: 18, marginBottom: 8 }}>
                Sammanfatta resultatet för dig själv. Är du redo att starta din
                resa?
              </Heading>
              <Button block>
                <Link to={routes.registrera}>Ja, skapa konto</Link>
              </Button>
            </Grid>
          </Card>
        </Grid>
      )}
    </Grid>
  )
}

export default Result
