import React from 'react'
import styles from './ProgressBar.module.css'

const ProgressBar = ({ currentCount, totalCount }) => {
  const progressSteps = () => {
    let steps = []
    for (let i = 1; i <= totalCount; i++) {
      if (i > currentCount) {
        steps.push(<div key={i} className={styles.stepItem}></div>)
      } else {
        steps.push(<div key={i} className={styles.stepItemDone}></div>)
      }
    }
    return steps
  }

  return <div className={styles.stepsOuter}>{progressSteps()}</div>
}

export default ProgressBar
