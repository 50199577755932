import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Backdrop, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SpeedDial, SpeedDialAction } from '@material-ui/lab'
import {
  Build,
  AccountCircle,
  Ballot,
  ExitToApp,
  Menu,
  Explore,
  DirectionsWalk,
} from '@material-ui/icons'
import routes from '../../app/routes'
import {
  logoutUser,
  getIsAuthenticated,
  getHasFinishedIntro,
  getCurrentSegment,
} from '../user/userSlice'
import { setFlashMessage } from '../flashmessage/flashMessageSlice'
import colors from '../../app/colors'
import { SMALL_SCREEN_LIMIT } from '../../app/constants'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  speedDialDesktop: {
    zIndex: theme.zIndex.drawer + 2,
    position: 'fixed',
    color: 'secondary',
    top: theme.spacing(2),
    left: theme.spacing(2),
    '& .MuiSpeedDial-fab': {
      backgroundColor: colors.mediumRed,
      '&:hover': { backgroundColor: colors.darkRed },
    },
  },
  speedDialMobile: {
    zIndex: theme.zIndex.drawer + 2,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: 'secondary',
    '& .MuiSpeedDial-fab': {
      backgroundColor: colors.mediumRed,
      '&:hover': { backgroundColor: colors.darkRed },
    },
  },
  fab: {
    backgroundColor: colors.mediumTeal,
    color: colors.lightGray,
    marginTop: theme.spacing(2),
    transition: 'all 0.5s ease',
    transform: 'scale(1)',
    '&:hover': {
      backgroundColor: colors.darkTeal,
      transform: 'scale(1.5) perspective(1px)',
    },
  },
  staticTooltipLabel: {
    width: '160px',
    fontSize: '18px',
    backgroundColor: colors.lightGray,
    color: colors.darkTeal,
    '&:hover': { cursor: 'pointer' },
  },
}))

const SpeedDialMenu = () => {
  const [showSpeedDialMenu, setShowSpeedDialMenu] = useState(false)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasFinishedIntro = useSelector(getHasFinishedIntro)
  const currentSegment = useSelector(getCurrentSegment)
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const matches = useMediaQuery(`(min-width:${SMALL_SCREEN_LIMIT})`)

  const toggleShowSpeedDialMenu = () => {
    setShowSpeedDialMenu(!showSpeedDialMenu)
  }

  const toTools = (e) => {
    history.push(routes.verktyg)
  }

  const toAccount = () => {
    history.push(routes.konto)
  }

  const toActivities = () => {
    history.push(routes.aktiviteter)
  }

  const toJourney = () => {
    history.push(routes.resa)
  }

  const toSegment = () => {
    history.push(`${routes.resa}/${currentSegment}`)
  }

  const logout = async () => {
    await dispatch(logoutUser())
    dispatch(
      setFlashMessage({
        message: 'Du har blivit utloggad. Stäng din webbläsare.',
      })
    )
    history.push(routes.home)
  }
  const actions = [
    {
      icon: <ExitToApp />,
      description: 'Logga ut',
      onActionClick: logout,
    },
    {
      icon: <AccountCircle />,
      description: 'Mitt konto',
      onActionClick: toAccount,
    },
    { icon: <Build />, description: 'Mina verktyg', onActionClick: toTools },
    {
      icon: <Ballot />,
      description: 'Mina aktiviteter',
      onActionClick: toActivities,
    },
    {
      icon: <DirectionsWalk />,
      description: 'Aktuell etapp',
      onActionClick: toSegment,
    },
    {
      icon: <Explore />,
      description: 'Min resa',
      onActionClick: toJourney,
    },
  ]

  if (matches) actions.reverse()

  if (!isAuthenticated || !hasFinishedIntro) return null

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={showSpeedDialMenu}
        onClick={toggleShowSpeedDialMenu}
      />
      <SpeedDial
        ariaLabel="SpeedDialMenu"
        icon={<Menu />}
        open={showSpeedDialMenu}
        onClick={toggleShowSpeedDialMenu}
        className={matches ? classes.speedDialDesktop : classes.speedDialMobile}
        direction={matches ? 'down' : 'up'}
      >
        {actions.map((action) => (
          <SpeedDialAction
            classes={{
              fab: classes.fab,
              staticTooltipLabel: classes.staticTooltipLabel,
            }}
            tooltipPlacement={matches ? 'right' : 'left'}
            key={action.description}
            icon={action.icon}
            tooltipTitle={action.description}
            tooltipOpen
            onClick={action.onActionClick}
          />
        ))}
      </SpeedDial>
    </div>
  )
}

export default SpeedDialMenu
