import React from 'react'
import { Bar, BarChart, Cell, YAxis } from 'recharts'
import styles from './BarChart.module.css'

const SimpleBarChart = ({ data, width, height, lineColor, domain, ticks }) => {
  return (
    <div className={styles.centerContent}>
      <div>
        <BarChart
          width={width ? width : 230}
          height={height ? height : 130}
          data={data}
          margin={{ top: 20, right: 10, bottom: 0, left: 0 }}
        >
          <Bar
            dataKey="value"
            isAnimationActive={false}
            radius={[4, 4, 0, 0]}
            label={{ position: 'top', fontWeight: 'bold' }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} strokeWidth={1} fill={entry.color} />
            ))}
          </Bar>
          <YAxis domain={domain} ticks={ticks} width={20} />
        </BarChart>
      </div>
      <div className={styles.line} style={{ background: `${lineColor}` }} />
      <div
        style={{
          marginTop: 10,
          paddingRight: 16,
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        {data.map((entry, index) => {
          if (entry.title === 'maxvalue') {
            return null
          }
          return (
            <span
              key={index}
              style={{ color: entry.color, fontWeight: 'bold' }}
            >
              {entry.title.toUpperCase()}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default SimpleBarChart
