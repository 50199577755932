import React from 'react'
import { useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import ActivityList from './ActivityList'
import ScreenHeader from '../../components/ScreenHeader'
import { getCurrentSegment, getJourneyLength } from '../user/userSlice'

const Activity = () => {
  const currentSegment = useSelector(getCurrentSegment)
  const journeyLength = useSelector(getJourneyLength)
  const { segmentNumber } = useParams()
  const parsedSegmentNumber = parseInt(segmentNumber)
  if (
    segmentNumber &&
    (isNaN(parsedSegmentNumber) ||
      parsedSegmentNumber < 1 ||
      parsedSegmentNumber > journeyLength)
  ) {
    return <Redirect to="/" />
  }
  const isFinished = parsedSegmentNumber < currentSegment
  return (
    <div style={{ marginTop: 32 }}>
      <ScreenHeader
        title="Mina aktiviteter"
        subtitle={
          segmentNumber
            ? `Etapp ${segmentNumber}` + (isFinished ? ' (avslutad)' : '')
            : 'Alla etapper'
        }
      />
      <Grid container direction="column" alignItems="center">
        <ActivityList segmentNumber={segmentNumber && parsedSegmentNumber} />
      </Grid>
    </div>
  )
}

export default Activity
