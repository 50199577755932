import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import { getHasFinishedQuiz } from '../quiz/quizSlice'
import Card from '../../components/Card'
import ScreenHeader from '../../components/ScreenHeader'
import PrimaryButton from '../../components/PrimaryButton'
import ChangeEmail from './ChangeEmail'
import ChangePassword from './ChangePassword'
import DeleteAccount from './DeleteAccount'
import routes from '../../app/routes'

const Account = () => {
  const hasFinishedQuiz = useSelector(getHasFinishedQuiz)
  return (
    <div className="userWrapper">
      <ScreenHeader title="Mitt konto" />
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Card>
            <ChangeEmail />
            <ChangePassword />
            <div style={{ marginBottom: 10, marginTop: 32 }}>
              <PrimaryButton color="dark" block style={{ marginBottom: 32 }}>
                <Link to={routes.quiz + (hasFinishedQuiz ? '/resultat' : '')}>
                  {hasFinishedQuiz ? 'Ditt Test-resultat' : 'Gör vårt Test'}
                </Link>
              </PrimaryButton>
              <DeleteAccount block />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Account
