import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { Col, Form, Row } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import validator from 'validator'
import Card from '../../components/Card'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'
import PrimaryButton from '../../components/PrimaryButton'
import StageDisplay from '../../components/StageDisplay'
import routes from '../../app/routes'
import { registerUser } from './userSlice'
import ScreenHeader from '../../components/ScreenHeader'
import { Text } from '../../components/typography'

const Register = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isRegistered, setIsRegistered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const register = async (values) => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      await dispatch(registerUser(values))
      setIsLoading(false)
      setIsRegistered(true)
    } catch (e) {
      setErrorMessage(e.message)
      setIsLoading(false)
    }
  }

  if (isRegistered) return <Redirect to={routes.valkommen} />
  return (
    <div className="userWrapper">
      <ScreenHeader
        title="Starta din resa"
        subtitle={
          <div>
            <span>Skapa konto.</span>
            <br />
            <span>Dina uppgifter stannar hos oss.</span>
          </div>
        }
        backButtonRoute={routes.home}
      />
      <StageDisplay stage="1" />
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Card>
            <Form name="basic" onFinish={register} form={form}>
              <Form.Item
                name="email"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: 'Din mejladress',
                    validateTrigger: 'onSubmit',
                  },
                  () => ({
                    validator(rule, value) {
                      if (validator.isEmail(value)) return Promise.resolve()

                      return Promise.reject(
                        'Den här adressen verkar tyvärr ogiltig'
                      )
                    },
                  }),
                ]}
              >
                <InputField
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  type="email"
                />
              </Form.Item>

              <Form.Item
                name="firstName"
                validateFirst={true}
                rules={[{ required: true, message: 'Ditt förnamn' }]}
              >
                <InputField placeholder="Förnamn" />
              </Form.Item>

              <Form.Item
                name="surname"
                validateFirst={true}
                rules={[{ required: true, message: 'Ditt efternamn' }]}
              >
                <InputField placeholder="Efternamn" />
              </Form.Item>

              <Form.Item
                name="registrationCode"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: 'Din registreringskod',
                  },
                ]}
              >
                <InputField placeholder="Registreringskod" />
              </Form.Item>

              <Form.Item
                name="password"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: 'Välj ett lösenord (minst 6 tecken)',
                    validateTrigger: 'onSubmit',
                  },
                  () => ({
                    validator(rule, value) {
                      if (value.length >= 6) return Promise.resolve()

                      return Promise.reject(
                        'Välj ett lösenord (minst 6 tecken)'
                      )
                    },
                  }),
                ]}
              >
                <InputField
                  password={true}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Lösenord"
                />
              </Form.Item>

              <Form.Item
                name="passwordRepeat"
                dependencies={['password']}
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: 'Repetera ditt lösenord',
                    validateTrigger: 'onSubmit',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject('Lösenorden matchar inte')
                    },
                  }),
                ]}
              >
                <InputField
                  password={true}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Repetera lösenord"
                />
              </Form.Item>

              {errorMessage !== '' && <FormError>{errorMessage}</FormError>}

              <Form.Item style={{ textAlign: 'center' }}>
                <PrimaryButton
                  style={{ marginTop: '10px' }}
                  loading={isLoading}
                  htmlType="submit"
                >
                  Skapa konto
                </PrimaryButton>
              </Form.Item>
            </Form>
            <Text style={{ fontSize: 12, lineHeight: 1.2 }}>
              För att hjälpa dig i din jobbresa behandlar vi personuppgifter om
              dig, du kan läsa mer om vår behandling{' '}
              <Link to={routes.personuppgifter}>här</Link>.
            </Text>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Register
