import React from 'react'
import { Route } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Question from './Question'
import Result from './Result'
import Start from './Start'
import ScreenHeader from '../../components/ScreenHeader'
import routes from '../../app/routes'

const Quiz = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingTop: 16, paddingBottom: 16 }}
    >
      <Route exact path={routes.quiz}>
        <ScreenHeader title="Test" backButtonRoute={routes.home} />
        <Start />
      </Route>
      <Route exact path={`${routes.quiz}/:number`}>
        <ScreenHeader title="Test" backButtonRoute={routes.quiz} />
        <Grid item>
          <Question />
        </Grid>
      </Route>
      <Route exact path={`${routes.quiz}/resultat`}>
        <Grid item>
          <Result />
        </Grid>
      </Route>
      <Route path={`${routes.quiz}/resultat/:token`}>
        <ScreenHeader title="Resultat" />
        <Grid item>
          <Result />
        </Grid>
      </Route>
    </Grid>
  )
}

export default Quiz
