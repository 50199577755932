import { configureStore } from '@reduxjs/toolkit'
import quizReducer from '../features/quiz/quizSlice'
import toolReducer from '../features/tool/toolSlice'
import userReducer from '../features/user/userSlice'
import activityReducer from '../features/activity/activitySlice'
import rewardReducer from '../features/reward/rewardSlice'
import flashMessageReducer from '../features/flashmessage/flashMessageSlice'

export default configureStore({
  reducer: {
    quiz: quizReducer,
    tool: toolReducer,
    user: userReducer,
    activity: activityReducer,
    reward: rewardReducer,
    flash: flashMessageReducer,
  },
})
