import React from 'react'
import clsx from 'clsx'
import { Button } from 'antd'
import styles from './PrimaryButton.module.css'

const PrimaryButton = ({
  children,
  type = 'primary',
  size = 'large',
  color = 'primary',
  ...props
}) => (
  <Button
    className={clsx(
      color === 'primary' && styles.primaryButton,
      color === 'light' && styles.primaryButtonLight,
      color === 'dark' && styles.primaryButtonDark
    )}
    type={type}
    size={size}
    {...props}
  >
    {children}
  </Button>
)

export default PrimaryButton
