import React from 'react'
import { Radio, withStyles } from '@material-ui/core'
import colors from '../../app/colors'

const TealRadio = withStyles({
  root: {
    color: colors.mediumTeal,
    '&$checked': {
      color: colors.mediumTeal,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const RedRadio = withStyles({
  root: {
    color: colors.mediumRed,
    '&$checked': {
      color: colors.mediumRed,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const BronzeRadio = withStyles({
  root: {
    color: colors.bronze,
    '&$checked': {
      color: colors.bronze,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const SilverRadio = withStyles({
  root: {
    color: colors.silver,
    '&$checked': {
      color: colors.silver,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const GoldRadio = withStyles({
  root: {
    color: colors.gold,
    '&$checked': {
      color: colors.gold,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

export { TealRadio, RedRadio, BronzeRadio, SilverRadio, GoldRadio }
