import React from 'react'
import clsx from 'clsx'
import styles from './Heading.module.css'

const Heading = ({ type, shadow, children, ...props }) => (
  <h1
    className={clsx(
      styles.heading,
      type === 'primary' && styles.headingPrimary,
      type === 'light' && styles.headingLight,
      type === 'dark' && styles.headingDark,
      shadow && styles.shadow
    )}
    {...props}
  >
    {children}
  </h1>
)

export { Heading }
