import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DesktopMainAppbar from './desktop/DesktopMainAppbar'
import PhoneMainAppbar from './phone/PhoneMainAppbar'
import { SMALL_SCREEN_LIMIT } from '../../app/constants'

const MainAppbarWrapper = () => {
  const matches = useMediaQuery(`(min-width:${SMALL_SCREEN_LIMIT})`)
  return matches ? <DesktopMainAppbar /> : <PhoneMainAppbar />
}

export default MainAppbarWrapper
