import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Grid, useMediaQuery } from '@material-ui/core'
import { ArrowLeftOutlined } from '@ant-design/icons'
import IconButton from './IconButton'
import { Heading, SubHeading } from './typography'

const ScreenHeader = ({ title, subtitle, backButtonRoute }) => {
  const history = useHistory()
  const narrowScreen = useMediaQuery(`(max-width: 300px)`)

  const width = narrowScreen ? '90vw' : 300

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      wrap="nowrap"
      style={{ marginBottom: 16 }}
    >
      <Grid
        container
        alignItems="center"
        style={{
          width,
        }}
      >
        <Grid item>
          <IconButton
            onClick={
              !backButtonRoute
                ? () => {
                    history.goBack()
                  }
                : undefined
            }
            shape="circle"
            icon={
              backButtonRoute ? (
                <Link to={backButtonRoute}>
                  <ArrowLeftOutlined />
                </Link>
              ) : (
                <ArrowLeftOutlined />
              )
            }
            style={{
              marginRight: 8,
            }}
          />
        </Grid>
        <Grid
          item
          style={{ flexGrow: 1, marginRight: 40, textAlign: 'center' }}
        >
          <Heading>{title}</Heading>
        </Grid>
      </Grid>
      <Grid item style={{ width }}>
        <SubHeading style={{ textAlign: 'center' }}>{subtitle}</SubHeading>
      </Grid>
    </Grid>
  )
}

export default ScreenHeader
