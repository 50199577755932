import React from 'react'
import {
  Grid,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'
import {
  RedRadio,
  BronzeRadio,
  SilverRadio,
  GoldRadio,
} from '../../components/radios'
import CustomPopover from './CustomPopover'

const Status = (props) => {
  const { value, onChange, bronze, silver, gold } = props

  const position = { position: 'relative' }
  return (
    <Grid>
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup name="verktyg" value={value} onChange={onChange}>
            <FormControlLabel
              value={'Inte påbörjat'}
              control={<RedRadio />}
              label="Inte påbörjat"
            />
            <div style={position}>
              <FormControlLabel
                value={'Bronsnivå'}
                control={<BronzeRadio />}
                label="Bronsnivå"
              />
              <CustomPopover title={bronze} content={'Bronsnivå'} />
            </div>
            <div style={position}>
              <FormControlLabel
                value={'Silvernivå'}
                control={<SilverRadio />}
                label="Silvernivå"
              />
              <CustomPopover title={silver} content={'Silvernivå'} />
            </div>
            <div style={position}>
              <FormControlLabel
                value={'Guldnivå'}
                control={<GoldRadio />}
                label="Guldnivå"
              />
              <CustomPopover title={gold} content={'Guldnivå'} />
            </div>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Status
