import { createSlice } from '@reduxjs/toolkit'
import { updateUserData } from '../../app/firebase'
import { getCurrentSegment, changeJourneyEndDate } from '../user/userSlice'
import activitySuggestions from './activity.json'

export { activitySuggestions }

export const slice = createSlice({
  name: 'activity',
  initialState: {
    activitiesById: {},
    allActivityIds: [],
  },
  reducers: {
    setActivity: (state, { payload }) => {
      const { id } = payload
      state.activitiesById[id] = payload
    },
    setNewActivities: (state, { payload }) => {
      payload.forEach((activity) => {
        const { id } = activity
        state.activitiesById[id] = activity
        state.allActivityIds.push(id)
      })
    },
    setActivityState: (state, { payload }) => {
      const { activitiesById } = payload
      state.activitiesById = activitiesById
      state.allActivityIds = Object.keys(activitiesById).sort(
        (a, b) => parseInt(a.substring(1)) - parseInt(b.substring(1))
      )
    },
  },
})

export const { setActivity, setNewActivities, setActivityState } = slice.actions

export const getActivitiesById = (state) => state.activity.activitiesById
export const getAllActivityIds = (state) => state.activity.allActivityIds
export const getActivityCount = (state) => state.activity.allActivityIds.length
export const getHasSelectedActivities = (state, segmentNumber) => {
  let ret = false
  state.activity.allActivityIds.forEach((id) => {
    if (state.activity.activitiesById[id].segmentNumber === segmentNumber) {
      ret = true
    }
  })
  return ret
}

export const getFinishedActivityCount = (state, segmentNumber) =>
  state.activity.allActivityIds.filter((id) => {
    const activity = state.activity.activitiesById[id]
    return activity.segmentNumber === segmentNumber && activity.isFinished
  }).length

export const getHasFinishedFirstActivity = (state) =>
  state.activity.allActivityIds.filter((id) => {
    const activity = state.activity.activitiesById[id]
    return activity.isFinished
  }).length > 1

export const saveActivity = (activity) => async (dispatch, getState) => {
  dispatch(setActivity(activity))

  const state = getState()
  const segmentNumber = getCurrentSegment(state)
  let hasFinishedAllActivities = state.activity.allActivityIds
    .map((id) => state.activity.activitiesById[id])
    .filter((activity) => activity.segmentNumber === segmentNumber)
    .every((activity) => activity.isFinished)

  if (hasFinishedAllActivities) {
    dispatch(changeJourneyEndDate())
  }

  updateUserData({ [activity.id]: activity }, '/activities')
}

export const saveNewActivities = (activities, segmentNumber) => async (
  dispatch,
  getState
) => {
  const mappedActivities = activities.map((activity, i) => ({
    id: 'a' + (getActivityCount(getState()) + i + 1),
    segmentNumber,
    isFinished: false,
    ...activity,
  }))
  dispatch(setNewActivities(mappedActivities))
  mappedActivities.forEach((activity) => {
    updateUserData({ [activity.id]: activity }, '/activities')
  })
}

// Returns true if user did not finish all activities in last segment AND has NOT yet selected activities for current segment
export const getUserMissedLastSegment = (state) => {
  const currentSegment = getCurrentSegment(state)
  if (currentSegment === 1) {
    return false
  }
  if (
    getFinishedActivityCount(state, currentSegment - 1) !== 5 &&
    !getHasSelectedActivities(state, currentSegment)
  ) {
    return true
  } else {
    return false
  }
}

export default slice.reducer
