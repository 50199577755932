import { useEffect } from 'react'
import { LeaderLine } from 'leader-line'

const LeaderLines = ({
  classId,
  numberOfLines,
  mainColor = 'black',
  numOfFullOpacityLines,
  animation,
  hideLines,
}) => {
  let lines = []
  let delayTimeouts = []
  LeaderLine.positionByWindowResize = false // prevents leaderLine own rerender

  let fullOpacityLines = numOfFullOpacityLines
    ? numOfFullOpacityLines
    : numberOfLines

  useEffect(() => {
    let isMounted = true // prevents issues with timeouts when component gets unmounted

    for (let i = 1; i < numberOfLines; i++) {
      let isFirstBox = document.getElementById(`${classId}${i}`)
      let isSecondBox = document.getElementById(`${classId}${i + 1}`)

      if (isFirstBox && isSecondBox) {
        let line = new LeaderLine(
          isFirstBox,
          isSecondBox,
          numberOfLines === 6
            ? mediumOptions[i - 1]
            : numberOfLines === 8
            ? longOptions[i - 1]
            : numberOfLines === 5
            ? segmentOptions[i - 1]
            : shortOptions[i - 1]
        )

        line.setOptions({
          color:
            i < fullOpacityLines
              ? mainColor
              : i === fullOpacityLines
              ? 'rgb(255,240,0, 0.7)'
              : 'rgb(0,0,0, 0.3)',
          dropShadow: true,
          dash: true,
          path: 'fluid',
          animation: true,
        })

        lines.push(line)
      }
    }

    const delayedLineDraw = (line, delayTime) => {
      return setTimeout(() => {
        if (isMounted) {
          try {
            line.show([animation ? 'draw' : 'none'], {
              duration: 900,
              timing: [0.58, 0, 0.42, 1],
            })
          } catch (error) {
            // might throw error if abusing window resize
          }
        }
      }, delayTime)
    }

    for (let i = 0; i < lines.length; i++) {
      if (!hideLines) {
        if (animation) {
          delayTimeouts.push(delayedLineDraw(lines[i], i * 900))
        } else {
          lines[i].show(['none'], {
            duration: 900,
            timing: [0.58, 0, 0.42, 1],
          })
        }
      }
    }

    return () => {
      // Clearing timeouts on unmount
      isMounted = false
      delayTimeouts.forEach((timeout) => {
        clearTimeout(timeout)
      })

      lines.forEach((line) => {
        line.remove()
      }) // removes lines on component removal
    }
  })

  return null
}

export default LeaderLines

let shortOptions = [
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    endSocket: 'bottom',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'left',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    hide: true,
  },
]

let mediumOptions = [
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    endSocket: 'bottom',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    endSocket: 'right',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
]

let longOptions = [
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    endSocket: 'bottom',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'left',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
]

let segmentOptions = [
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    endSocket: 'bottom',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
  {
    startPlug: 'behind',
    endPlug: 'behind',
    size: 3,
    startSocket: 'top',
    hide: true,
  },
]
