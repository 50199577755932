import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FrownTwoTone,
  SmileTwoTone,
} from '@ant-design/icons'
import { Grid, Slider } from '@material-ui/core'
import Card from '../../components/Card'
import { Text, SubHeading } from '../../components/typography'
import IconButton from '../../components/IconButton'
import ProgressBar from '../../components/ProgressBar'
import routes from '../../app/routes'
import {
  answerQuestion,
  getQuestionCount,
  getQuestionsByNumber,
  POINT_RANGE as range,
  SCORE_MAP as scoreMap,
  setScore,
  setWeight,
} from './quizSlice'

const Question = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const questionNumber = +params.number
  const questionsByNumber = useSelector(getQuestionsByNumber)
  const currentQuestion = questionsByNumber[questionNumber]
  const previousQuestion = questionsByNumber[questionNumber - 1]
  const questionCount = useSelector(getQuestionCount)

  const linkForward =
    questionNumber < questionCount
      ? `${routes.quiz}/${questionNumber + 1}`
      : `${routes.quiz}/resultat`

  const disableBackButton = questionNumber === 1

  // Make sure user cannot jump straight into a question without answering previous question, or type invalid param into address bar
  if (Number.isNaN(questionNumber)) {
    return <Redirect to={routes.quiz} />
  }
  if (
    !currentQuestion ||
    (questionNumber !== 1 && !previousQuestion.isAnswered)
  ) {
    return <Redirect to={`${routes.quiz}/1`} />
  }

  return (
    <Card>
      <Grid container direction="column" alignItems="stretch">
        <div style={{ textAlign: 'center' }}>
          <SubHeading>{currentQuestion.title}</SubHeading>
        </div>
        <Text>{currentQuestion.question}</Text>
        <Slider
          min={range.start}
          max={range.end}
          onChange={(_, v) =>
            dispatch(setScore({ number: questionNumber, score: v }))
          }
          value={currentQuestion.score}
          valueLabelDisplay="auto"
        />
        <Feedback value={currentQuestion.score} icons />
        <Text>{currentQuestion.importance}</Text>
        <Slider
          min={range.start}
          max={range.end}
          onChange={(_, v) =>
            dispatch(setWeight({ number: questionNumber, weight: v }))
          }
          value={currentQuestion.weight}
          valueLabelDisplay="auto"
        />
        <Feedback value={currentQuestion.weight} />
        <Grid container justify="space-around" style={{ marginTop: 16 }}>
          <Grid item>
            <IconButton
              disabled={disableBackButton}
              onClick={() => {
                history.goBack()
              }}
              shape="circle"
              icon={<ArrowLeftOutlined />}
            />
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => dispatch(answerQuestion(questionNumber))}
              shape="circle"
              icon={
                <Link to={linkForward}>
                  <ArrowRightOutlined />
                </Link>
              }
            />
          </Grid>
        </Grid>
        <Grid container justify="space-around" style={{ marginTop: 16 }}>
          <ProgressBar
            currentCount={questionNumber}
            totalCount={questionCount}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

const Feedback = ({ value, icons }) => {
  const negativeColor =
    scoreMap[value < range.midpoint ? value : range.midpoint].color
  const positiveColor =
    scoreMap[value > range.midpoint ? value : range.midpoint].color
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 16,
        fontWeight: 'bold',
      }}
    >
      {icons ? (
        <>
          <FrownTwoTone twoToneColor={negativeColor} style={{ fontSize: 20 }} />
          <Text
            style={{
              color: scoreMap[value].color,
              textAlign: 'center',
            }}
          >
            {scoreMap[value].scoreTag}
          </Text>
          <SmileTwoTone twoToneColor={positiveColor} style={{ fontSize: 20 }} />
        </>
      ) : (
        <>
          <Text style={{ color: negativeColor }}>{range.start}</Text>
          <Text
            style={{
              color: scoreMap[value].color,
              textAlign: 'center',
            }}
          >
            {scoreMap[value].weightTag}
          </Text>
          <Text style={{ color: positiveColor }}>{range.end}</Text>
        </>
      )}
    </div>
  )
}

export default Question
