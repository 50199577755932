import React, { useState } from 'react'
import { Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import styles from './CustomPopover.module.css'

const CustomPopover = (props) => {
  const [visible, setVisible] = useState(false)
  const { title } = props

  const hide = () => {
    setVisible(false)
  }

  const handleVisibleChange = (visible) => {
    setVisible(visible)
  }

  return (
    <div className={styles.popoverPosition}>
      <Popover
        overlayStyle={{
          maxWidth: '240px',
        }}
        placement={'topRight'}
        align={{
          offset: [4, 0],
          overflow: { adjustX: true, adjustY: false },
        }}
        title={title}
        content={
          <button className={styles.button} onClick={hide}>
            Stäng
          </button>
        }
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <InfoCircleOutlined className={styles.circle} />
      </Popover>
    </div>
  )
}

export default CustomPopover
