import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Form, Row } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { Heading } from '../../components/typography'
import FormError from '../../components/FormError'
import InputField from '../../components/InputField'
import PrimaryButton from '../../components/PrimaryButton'
import { changeUserPassword, getUserEmail } from './userSlice'
import { setFlashMessage } from '../flashmessage/flashMessageSlice'

const ChangePassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const userEmail = useSelector(getUserEmail)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const changePassword = async (values) => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      values.email = userEmail
      await changeUserPassword(values)
      form.resetFields(['currentPassword', 'newPassword', 'newPasswordRepeat'])
      dispatch(setFlashMessage({ message: 'Ditt lösenord är uppdaterat' }))
    } catch (e) {
      form.resetFields(['currentPassword'])
      setErrorMessage(e.message)
    }
    removeMessages()
    setIsLoading(false)
  }

  const removeMessages = () => {
    setTimeout(() => {
      setErrorMessage('')
    }, 3000)
  }

  return (
    <Row type="flex" justify="center" align="middle" className="userWrapper">
      <Col flex={1}>
        <Heading type="primary" align="center">
          Byt lösenord
        </Heading>

        <Form name="changePasswordForm" onFinish={changePassword} form={form}>
          <Form.Item
            name="currentPassword"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Ditt gamla lösenord',
                validateTrigger: 'onSubmit',
              },
              () => ({
                validator(rule, value) {
                  if (value.length >= 6) return Promise.resolve()

                  return Promise.reject(
                    'Ditt lösenord är 6 tecken eller längre'
                  )
                },
              }),
            ]}
          >
            <InputField
              password={true}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Nuvarande lösenord"
            />
          </Form.Item>

          <Form.Item
            name="newPassword"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Välj ett lösenord (minst 6 tecken)',
                validateTrigger: 'onSubmit',
              },
              () => ({
                validator(rule, value) {
                  if (value.length >= 6) return Promise.resolve()

                  return Promise.reject('Välj ett lösenord (minst 6 tecken)')
                },
              }),
            ]}
          >
            <InputField
              password={true}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Nytt lösenord"
            />
          </Form.Item>

          <Form.Item
            name="newPasswordRepeat"
            validateTrigger="onChange"
            dependencies={['newPassword']}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Repetera ditt lösenord',
                validateTrigger: 'onSubmit',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('Lösenorden matchar inte')
                },
              }),
            ]}
          >
            <InputField
              password={true}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Repetera nytt lösenord"
            />
          </Form.Item>

          {errorMessage !== '' && <FormError>{errorMessage}</FormError>}

          <Form.Item style={{ textAlign: 'center' }}>
            <PrimaryButton loading={isLoading} htmlType="submit">
              Byt lösenord
            </PrimaryButton>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default ChangePassword
