import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
//TODO: Change to BrowserRouter instead and configure host!
import { BrowserRouter as Router } from 'react-router-dom'
import 'antd/dist/antd.css'
import './index.css'
import { ThemeProvider } from '@material-ui/styles'
import CheckActiveSession from './features/user/CheckActiveSession'
import App from './App'
import store from './app/store'
import theme from './app/theme'
import * as serviceWorker from './serviceWorker'
import ErrorBoundary from '../src/components/ErrorBoundary'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router basename="/">
        <CheckActiveSession>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </CheckActiveSession>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
