import { createSlice } from '@reduxjs/toolkit'
import qualifications from './tool.json'
import { updateUserData } from '../../app/firebase'

const qualificationsById = {}
const allQualificationIds = []

qualifications.forEach((q) => {
  const id = q.id
  qualificationsById[id] = {
    id,
    title: q.title,
    description: q.description,
    bronze: q.bronze,
    silver: q.silver,
    gold: q.gold,
    toolStatus: 'Inte påbörjat',
  }
  allQualificationIds.push(id)
})

export const slice = createSlice({
  name: 'tool',
  initialState: {
    qualificationsById,
    allQualificationIds,
  },
  reducers: {
    setToolStatus: (state, { payload }) => {
      const { id, toolStatus } = payload
      state.qualificationsById[id].toolStatus = toolStatus
    },
    setToolState: (state, { payload }) => {
      const { qualifications } = payload
      qualifications.forEach((qualification) => {
        state.qualificationsById[qualification.id].toolStatus =
          qualification.toolStatus
      })
    },
  },
})

export const { setToolState, setToolStatus } = slice.actions

export const getQualificationsById = (state) => state.tool.qualificationsById

export const getAllQualificationIds = (state) => state.tool.allQualificationsIds

export const getQualificationCount = (state) =>
  state.tool.allQualificationIds.length

export const finishToolSelection = () => async (_dispatch, getState) => {
  const state = getState().tool
  const tools = state.allQualificationIds.map((id) => {
    const qualification = state.qualificationsById[id]
    return {
      id,
      toolStatus: qualification.toolStatus,
    }
  })
  updateUserData({ tools })
}

export default slice.reducer
