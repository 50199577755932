import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Card from '../../components/Card'
import { Text, SubHeading } from '../../components/typography'
import Button from '../../components/PrimaryButton'
import routes from '../../app/routes'
import { resetQuiz } from './quizSlice'

const Start = () => {
  const dispatch = useDispatch()
  return (
    <Card>
      <Grid container direction="column" alignItems="center">
        <SubHeading>Innan vi startar</SubHeading>
        <Text>
          Det här är ett test som får dig att reflektera över din situation —
          oavsett om du är arbetssökande, har anställning eller tillfälligt
          uppdrag.
          <br />
          Besvara frågorna för att få koll på hur väl du trivs i dag och vad du
          önskar av jobbet i morgon. Vad är viktigast för dig och din yrkesroll?
        </Text>
        <SubHeading style={{ marginTop: 16, marginBottom: 0 }}>
          Instruktioner
        </SubHeading>
        <Text style={{ marginBottom: '1em' }}>
          Du får 12 påståenden om din senaste tjänst/ditt nuvarande arbete.
          Markera hur väl varje påstående stämmer.
        </Text>
        <Text style={{ marginBottom: '1em' }}>
          Hur prioriterar du när det gäller yrke och arbetsmiljö? Använd skala
          1–5 för att visa vad som är viktigt för dig. <br />
          <strong>
            1 = inte alls viktigt
            <br />5 = mycket viktigt
          </strong>
        </Text>
        <Button style={{ width: '100%' }} onClick={() => dispatch(resetQuiz())}>
          <Link to={`${routes.quiz}/1`}>Starta test</Link>
        </Button>
      </Grid>
    </Card>
  )
}

export default Start
