import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DesktopSegmentAppbar from './desktop/DesktopSegmentAppbar'
import PhoneSegmentAppbar from './phone/PhoneSegmentAppbar'
import { SMALL_SCREEN_LIMIT } from '../../app/constants'

const SegmentAppbarWrapper = () => {
  const matches = useMediaQuery(`(min-width:${SMALL_SCREEN_LIMIT})`)
  return matches ? <DesktopSegmentAppbar /> : <PhoneSegmentAppbar />
}

export default SegmentAppbarWrapper
