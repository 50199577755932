import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { TrophyOutlined } from '@ant-design/icons'
import { getRewardsBySegment, saveReward } from './rewardSlice'
import PrimaryButton from '../../components/PrimaryButton'
import { Heading, Text } from '../../components/typography'
import colors from '../../app/colors'

const RewardChooserDialog = ({ segmentNumber, open, onClose }) => {
  const [reward, setReward] = useState('')
  const alreadyCreatedReward = useSelector(getRewardsBySegment)[segmentNumber]
  const dispatch = useDispatch()
  const isError = reward.length > 100

  useEffect(() => {
    if (alreadyCreatedReward) {
      setReward(alreadyCreatedReward)
    }
  }, [alreadyCreatedReward])

  //TODO: Loading state, error handling...
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="reward-chooser-dialog"
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <TrophyOutlined
              style={{
                fontSize: 100,
                color: colors.darkYellow,
              }}
            />
          </Grid>
          <Grid item>
            <Heading id="reward-chooser-dialog">Välj en belöning</Heading>
          </Grid>
          <Grid item>
            <Text>
              Hur vill du fira när du är klar med den här etappens aktiviteter?
            </Text>
          </Grid>
          <Grid item style={{ width: '100%', paddingTop: 16 }}>
            <TextField
              fullWidth
              error={isError}
              helperText={isError && 'Max 100 tecken'}
              label="Belöning"
              placeholder="Skriv din belöning här!"
              multiline
              rows={4}
              value={reward}
              onChange={(e) => setReward(e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          disabled={isError}
          onClick={() => {
            dispatch(saveReward({ segmentNumber, reward }))
            onClose()
          }}
        >
          Spara
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export default RewardChooserDialog
