import React from 'react'
import styles from './Background.module.css'

// Background images
import city from '../assets/svg/City.svg'
import desert from '../assets/svg/Desert.svg'
import hills from '../assets/svg/Hills.svg'
import mixed from '../assets/svg/Mixed.svg'
import startvy from '../assets/svg/Startvy.svg'
import winter from '../assets/svg/Winter.svg'

const Background = ({ image, children }) => {
  switch (image) {
    case 'city':
      image = city
      break
    case 'desert':
      image = desert
      break
    case 'hills':
      image = hills
      break
    case 'mixed':
      image = mixed
      break
    case 'winter':
      image = winter
      break
    default:
      image = startvy
      break
  }
  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default Background
