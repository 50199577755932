import React from 'react'
import { Grid, Divider } from '@material-ui/core'
import Card from '../../components/Card'
import { Text, Heading, SubHeading } from '../../components/typography'
import colors from '../../app/colors'
import BarChart from './BarChart'
import { POINT_RANGE as range } from './quizSlice'

const ChartCard = ({ heading, questions }) => (
  <Card>
    <Heading type="primary" style={{ textAlign: 'center' }}>
      {heading}
    </Heading>
    {questions.map((question, i) => {
      const chartData = [
        {
          title: 'Prioritet',
          value: question.weight,
          color: colors.mediumTeal,
        },
        {
          title: 'Nuläge',
          value: question.score,
          color: getColor(question.score),
        },
      ]
      return (
        <Grid key={i} container direction="column" alignItems="center">
          <Divider style={{ marginTop: 8, marginBottom: 8, width: 250 }} />
          <SubHeading type="primary" style={{ fontSize: 18 }}>
            {question.title}
          </SubHeading>
          <Text style={{ fontWeight: 'bold' }}>{question.question}</Text>
          <BarChart
            lineColor={colors.mediumGray}
            knownMaxValue={range.end}
            data={chartData}
            domain={[0, range.end]}
            ticks={[range.start, range.midpoint, range.end]}
          />
        </Grid>
      )
    })}
  </Card>
)

const getColor = (score) =>
  score < range.midpoint ? colors.mediumRed : colors.mediumGreen

export default ChartCard
