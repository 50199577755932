import { red, green, blue, yellow, grey, amber } from '@material-ui/core/colors'

// Color Palette: https://material-ui.com/customization/color/#color-palette
// Try to only add colors that are in the color palette

// TODO: Keep in sync with colors.css
// NOTE: Hover over the colors to see their hex color equivalents
const colors = {
  // Teal
  lightTeal: '#b0fff1',
  mediumTeal: '#7ecfbe',
  darkTeal: '#4d9e8e',

  // Red
  mediumRed: red[300],
  darkRed: red[500],

  // Green
  mediumGreen: green[300],
  darkGreen: green[500],

  // Blue
  mediumBlue: blue[300],
  lightBlue: blue[500],

  // Yellow
  darkYellow: yellow[500],

  // Amber
  mediumAmber: amber[400],

  // Gray
  lightGray: grey[100],
  mediumGray: grey[300],
  darkGray: grey[800],
  // Gray-Black
  black: grey[900],
  // Grey Transparent
  transparentGrey: 'rgba(0, 0, 0, 0.15)',

  // Bronze
  bronze: '#a17419',

  // Silver
  silver: '#b7b7b7',

  // Gold
  gold: '#d5a500',
}

export default colors
