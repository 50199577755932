import React from 'react'
import clsx from 'clsx'
import { Grid } from '@material-ui/core'
import styles from './StageDisplay.module.css'

const StageDisplay = ({ stage }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justify="space-around"
    className={styles.grid}
  >
    <Grid
      item
      className={clsx(styles.stage, stage === '1' && styles.stageHighlight)}
    >
      1
    </Grid>
    <Grid
      item
      className={clsx(styles.stage, stage === '2' && styles.stageHighlight)}
    >
      2
    </Grid>
    <Grid
      item
      className={clsx(styles.stage, stage === '3' && styles.stageHighlight)}
    >
      3
    </Grid>
  </Grid>
)

export default StageDisplay
