import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link, Route, Redirect } from 'react-router-dom'
import {
  getJourneyLength,
  getIsAuthenticated,
  getJourneyDaysLeft,
} from '../user/userSlice'
import {
  getHasSelectedActivities,
  getFinishedActivityCount,
} from '../activity/activitySlice'
import SegmentPlanner from './SegmentPlanner'
import SegmentAppbarWrapper from '../../components/appbar/SegmentAppbarWrapper'
import Activity from '../activity/Activity'
import PrimaryButton from '../../components/PrimaryButton'
import Background from '../../components/Background'
import routes from '../../app/routes'
import LeaderLine from './LeaderLine'
import NumberBox from './NumberBox'
import SegmentFinishDialog from './SegmentFinishDialog'
import CheckAvatar from '../../components/CheckAvatar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { TABLET_SCREEN_LIMIT } from '../../app/constants'

const JourneySegment = () => {
  const journeyDaysLeft = useSelector(getJourneyDaysLeft)

  const [responsiveBoxes, setBoxes] = useState([])
  let { segmentNumber } = useParams()
  segmentNumber = parseInt(segmentNumber)

  const isAuthenticated = useSelector(getIsAuthenticated)
  const journeyLength = useSelector(getJourneyLength)
  const hasSelectedActivities = useSelector((state) =>
    getHasSelectedActivities(state, segmentNumber)
  )
  const finishedActivityCount = useSelector((state) =>
    getFinishedActivityCount(state, segmentNumber)
  )

  const backgroundImage =
    backgroundMap[journeyLength]?.[segmentNumber] ?? 'mixed'

  const isMobileOrTabletWidth = useMediaQuery(
    `(max-width:${TABLET_SCREEN_LIMIT})`
  )

  // TODO own component? makes pretty much same thing as in MainJourney
  useEffect(() => {
    const createJourneyBoxes = () => {
      let finalPositions
      if (backgroundImage === 'hills') {
        finalPositions = isMobileOrTabletWidth
          ? hillsMobilePositions
          : hillsDesktopPositions
      } else if (backgroundImage === 'winter') {
        finalPositions = isMobileOrTabletWidth
          ? winterMobilePositions
          : winterDesktopPositions
      } else if (backgroundImage === 'desert') {
        finalPositions = isMobileOrTabletWidth
          ? desertMobilePositions
          : desertDesktopPositions
      } else if (backgroundImage === 'city') {
        finalPositions = isMobileOrTabletWidth
          ? cityMobilePositions
          : cityDesktopPositions
      }
      let numberedBoxes = []
      for (let i = 5; i > 0; i--) {
        numberedBoxes.push(
          <Link to={`${routes.resa}/${segmentNumber}/aktiviteter`} key={i}>
            <NumberBox
              positionLeft={finalPositions[i - 1].left}
              positionTop={finalPositions[i - 1].top}
              number={i}
              type={backgroundImage === 'winter' ? 'primary' : 'light'}
              round
              icon={i <= finishedActivityCount && <CheckAvatar />}
            />
          </Link>
        )
      }
      numberedBoxes.push(
        <LeaderLine
          key={100}
          mainColor={
            backgroundImage === 'winter'
              ? 'rgb(0,0,0,0.7)'
              : 'rgb(255,255,255,0.7)'
          }
          classId="box"
          numberOfLines={5}
        />
      )
      return numberedBoxes
    }
    setBoxes(createJourneyBoxes())
    let timeout = null
    const resizeListener = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => setBoxes(createJourneyBoxes(timeout)), 50)
    }
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [
    backgroundImage,
    finishedActivityCount,
    isMobileOrTabletWidth,
    segmentNumber,
  ])

  if (!isAuthenticated) return <Redirect to={routes.home} />

  const redirectToFinish = journeyDaysLeft <= 0

  return (
    <Background image={backgroundImage}>
      <Route exact path={`${routes.resa}/:segmentNumber`}>
        {redirectToFinish ? (
          <Redirect to={routes.avslutning} />
        ) : hasSelectedActivities ? (
          <>
            <SegmentAppbarWrapper />
            <div style={{ paddingTop: 200 }}>{responsiveBoxes}</div>
            <div
              style={{
                position: 'absolute',
                left: '3%',
                bottom: '1%',
              }}
            >
              <Link to={`${routes.resa}/${segmentNumber}/aktiviteter`}>
                <PrimaryButton
                  style={{
                    fontWeight: 'bold',
                  }}
                  color="light"
                >
                  Aktiviteter
                </PrimaryButton>
              </Link>
            </div>
          </>
        ) : (
          <SegmentPlanner segmentNumber={segmentNumber} />
        )}
      </Route>
      <Route path={`${routes.resa}/:segmentNumber/aktiviteter`}>
        <Activity />
        <Route exact path={`${routes.resa}/:segmentNumber/aktiviteter/klar`}>
          <SegmentFinishDialog
            segmentNumber={segmentNumber}
            open
            onClose={() => {}}
          />
        </Route>
      </Route>
    </Background>
  )
}

export default JourneySegment

const backgroundMap = {
  4: {
    1: 'hills',
    2: 'winter',
    3: 'desert',
    4: 'city',
  },
  6: {
    1: 'hills',
    2: 'winter',
    3: 'winter',
    4: 'desert',
    5: 'desert',
    6: 'city',
  },
  8: {
    1: 'hills',
    2: 'hills',
    3: 'hills',
    4: 'winter',
    5: 'winter',
    6: 'desert',
    7: 'desert',
    8: 'city',
  },
}

// Desktop positions
let hillsDesktopPositions = [
  { left: '58%', top: '85%' },
  { left: '43%', top: '72%' },
  { left: '55%', top: '55%' },
  { left: '40.5%', top: '44%' },
  { left: '56%', top: '34%' },
]

let winterDesktopPositions = [
  { left: '58%', top: '85%' },
  { left: '43%', top: '72%' },
  { left: '55%', top: '58%' },
  { left: '40.5%', top: '43%' },
  { left: '59%', top: '34%' },
]

let desertDesktopPositions = [
  { left: '58%', top: '85%' },
  { left: '43%', top: '72%' },
  { left: '55%', top: '56%' },
  { left: '40.5%', top: '45%' },
  { left: '58%', top: '34%' },
]

let cityDesktopPositions = [
  { left: '58%', top: '91%' },
  { left: '43%', top: '77%' },
  { left: '49%', top: '55%' },
  { left: '38%', top: '45%' },
  { left: '46%', top: '33%' },
]

// Mobile positions
let hillsMobilePositions = [
  { left: '58%', top: '83%' },
  { left: '24%', top: '67%' },
  { left: '59%', top: '55%' },
  { left: '28.5%', top: '44%' },
  { left: '66%', top: '36%' },
]

let winterMobilePositions = [
  { left: '58%', top: '83%' },
  { left: '24%', top: '67%' },
  { left: '63%', top: '55%' },
  { left: '26.5%', top: '44%' },
  { left: '71%', top: '33%' },
]

let desertMobilePositions = [
  { left: '58%', top: '83%' },
  { left: '20%', top: '67%' },
  { left: '59%', top: '55%' },
  { left: '14.5%', top: '42%' },
  { left: '70%', top: '33%' },
]

let cityMobilePositions = [
  { left: '55%', top: '88%' },
  { left: '7%', top: '75%' },
  { left: '44%', top: '52%' },
  { left: '10%', top: '44%' },
  { left: '32.5%', top: '33.5%' },
]
