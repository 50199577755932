import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { getIsAuthenticated } from '../features/user/userSlice'
import { Text, Heading } from './typography'
import Button from './PrimaryButton'
import routes from '../app/routes'
import Background from './Background'
import vertoLogo from '../assets/svg/verto.svg'
const HomeScreen = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  if (isAuthenticated) {
    return <Redirect to={routes.resa} />
  }
  return (
    <Background>
      <Grid
        container
        style={{ marginTop: '25vh' }}
        direction="column"
        alignItems="center"
      >
        <Grid
          item
          style={{
            textAlign: 'center',
            marginBottom: 16,
            textShadow: '1px 1px 2px rgba(44, 44, 44, 0.336)',
          }}
        >
          <Heading type="light">Jobbstigen</Heading>
          <Text type="light" style={{ fontWeight: 'bold' }}>
            Vägen till ditt nya jobb
          </Text>
          <Button color="light" block style={{ marginTop: 32 }}>
            <Link to={routes.login}>Logga in</Link>
          </Button>
          <Button
            color="light"
            block
            style={{ marginTop: 16, marginBottom: 48 }}
          >
            <Link to={routes.registrera}>Kom igång</Link>
          </Button>
          <Text type="light">Osäker på om du ska söka nytt jobb?</Text>
          <Button color="dark" block style={{ marginTop: 8 }}>
            <Link to={routes.quiz}>Gör ett jobbtest</Link>
          </Button>
          <a href="https://verto.se">
            <img
              src={vertoLogo}
              alt="logo"
              style={{ width: 150, marginTop: 40 }}
            />
          </a>
        </Grid>
      </Grid>
    </Background>
  )
}
export default HomeScreen
