import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowDropDown } from '@material-ui/icons'
import {
  activitySuggestions,
  saveNewActivities,
} from '../activity/activitySlice'
import { getRewardsBySegment } from '../reward/rewardSlice'
import RewardChooserDialog from '../reward/RewardChooserDialog'
import ScreenHeader from '../../components/ScreenHeader'
import { Text } from '../../components/typography'
import Button from '../../components/PrimaryButton'
import Card from '../../components/Card'
import routes from '../../app/routes'

const EMPTY_ACTIVITY = { title: '', description: '' }

const useStyles = makeStyles(() => ({
  formControl: {
    maxWidth: '100%',
  },
  select: {
    whiteSpace: 'inherit',
  },
  textField: {
    color: 'rgba(0, 0, 0, 0.55)',
  },
}))

//TODO: Heading should have shadow for contrast against light background
//TODO: Button should have explanation why it's disabled
const SegmentPlanner = ({ segmentNumber }) => {
  const classes = useStyles()
  const [activities, setActivities] = useState(
    Array(5)
      .fill()
      .map((_) => ({ ...EMPTY_ACTIVITY }))
  )
  const [openState, setOpenState] = useState(Array(5).fill(false))
  const [rewardDialogOpen, setRewardDialogOpen] = useState(false)
  const reward = useSelector(getRewardsBySegment)[segmentNumber]
  const dispatch = useDispatch()
  const disableNext =
    activities.length < 5 ||
    !reward ||
    activities.find((a) => !a.description || a.titleError || a.descriptionError)
  const handleSelectChange = (event, index) => {
    const value = event.target.value
    const updatedActivities = [...activities]
    if (value.isOwnActivity) {
      updatedActivities[index] = { ...value, title: '' }
    } else {
      updatedActivities[index] = value
    }
    closeSelect(index)
    setActivities(updatedActivities)
  }
  const handleTitleChange = (event, index) => {
    const updatedActivities = [...activities]
    updatedActivities[index].title = event.target.value
    updatedActivities[index].titleError = event.target.value.length > 40
    setActivities(updatedActivities)
  }

  const handleDescriptionChange = (event, index) => {
    const updatedActivities = [...activities]
    updatedActivities[index].description = event.target.value
    updatedActivities[index].descriptionError = event.target.value.length > 200
    setActivities(updatedActivities)
  }

  const resetSelect = (index) => {
    const updatedActivities = [...activities]
    updatedActivities[index] = { ...EMPTY_ACTIVITY }
    openSelect(index)
    setActivities(updatedActivities)
  }

  const openSelect = (index) => {
    const newState = [...openState]
    newState[index] = true
    setOpenState(newState)
  }

  const closeSelect = (index) => {
    const newState = [...openState]
    newState[index] = false
    setOpenState(newState)
  }

  const menuItems = activitySuggestions.map((activity, i) => (
    <MenuItem
      style={{ whiteSpace: 'normal' }}
      key={activity.title}
      value={activity}
      divider={i + 1 < activitySuggestions.length}
      classes={{ root: classes.menuItem }}
    >
      {activity.title}
    </MenuItem>
  ))
  let selects = []
  for (let i = 0; i < 5; i++) {
    selects.push(
      <FormControl
        key={'select-' + i}
        style={{ marginTop: 16 }}
        classes={{ root: classes.formControl }}
      >
        {activities[i].isOwnActivity ? (
          <TextField
            label={`Aktivitet ${i + 1}`}
            placeholder="Namn på din aktivitet"
            autoFocus
            multiline
            rowsMax={2}
            error={activities[i].titleError}
            helperText={activities[i].titleError && 'Max 40 tecken'}
            value={activities[i].title}
            onChange={(e) => handleTitleChange(e, i)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={() => resetSelect(i)}
                  >
                    <ArrowDropDown />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            select
            multiline
            label={`Aktivitet ${i + 1}`}
            value={activities[i].title ? activities[i] : ''}
            onChange={(e) => handleSelectChange(e, i)}
            SelectProps={{
              open: openState[i],
              onOpen: () => openSelect(i),
              onClose: () => closeSelect(i),
              classes: { root: classes.select },
            }}
          >
            {menuItems}
          </TextField>
        )}
        {activities[i].title && (
          <TextField
            style={{ marginTop: 16 }}
            disabled={!activities[i].isOwnActivity}
            error={activities[i].descriptionError}
            helperText={activities[i].descriptionError && 'Max 200 tecken'}
            id="description"
            label="Beskrivning"
            placeholder="Beskriv din aktivitet"
            multiline
            value={activities[i].description}
            onChange={(e) => handleDescriptionChange(e, i)}
            variant="outlined"
            InputProps={{ classes: { disabled: classes.textField } }}
          />
        )}
      </FormControl>
    )
  }
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingTop: 16, paddingBottom: 16 }}
    >
      <ScreenHeader
        title={`Planera etapp ${segmentNumber}`}
        subtitle="Välj aktiviteter"
      />
      <Grid item style={{ textAlign: 'center', marginBottom: 16 }}></Grid>
      <Card>
        <Grid container direction="column" alignItems="stretch">
          <Text>
            Varje aktivitet är ett steg i rätt riktning. Vilka fem aktiviteter
            känns bra att göra under den här etappen? Det går fint att göra
            samma aktivitet flera gånger.
          </Text>
          {selects}
          <Button
            style={{ marginTop: 32 }}
            onClick={() => setRewardDialogOpen(true)}
            color={reward ? 'dark' : 'primary'}
          >
            {reward ? 'Ändra belöning' : 'Välj belöning'}
          </Button>
          <Button
            style={{ marginTop: 16 }}
            onClick={() =>
              dispatch(
                saveNewActivities(
                  activities.map(({ title, description }) => ({
                    title,
                    description,
                  })),
                  segmentNumber
                )
              )
            }
            disabled={disableNext}
          >
            <Link to={`${routes.resa}/${segmentNumber}`}>Starta etapp</Link>
          </Button>
        </Grid>
      </Card>
      <RewardChooserDialog
        open={rewardDialogOpen}
        onClose={() => setRewardDialogOpen(false)}
        segmentNumber={segmentNumber}
      />
    </Grid>
  )
}

export default SegmentPlanner
