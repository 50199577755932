import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  flashMessage: '',
  isError: false,
}

export const slice = createSlice({
  name: 'flash',
  initialState,
  reducers: {
    setFlashMessage: (state, { payload }) => {
      state.flashMessage = payload.message
      state.isError = false
    },
    clearFlashMessage: () => {
      return {
        flashMessage: '',
        isError: false,
      }
    },
    setFlashMessageError: (state, { payload }) => {
      state.flashMessage = payload.message
      state.isError = true
    },
  },
})

export const {
  setFlashMessage,
  setFlashMessageError,
  clearFlashMessage,
} = slice.actions

export const getFlashMessage = (state) => {
  return state.flash.flashMessage
}

export const getErrorStatus = (state) => {
  return state.flash.isError
}

export default slice.reducer
