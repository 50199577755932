import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Background from './components/Background'
import HomeScreen from './components/HomeScreen'
import Activity from './features/activity/Activity'
import JourneyLengthChooser from './features/journey/JourneyLengthChooser'
import Quiz from './features/quiz/Quiz'
import Tool from './features/tool/Tool'
import ToolList from './features/tool/ToolList'
import Login from './features/user/Login'
import Account from './features/user/Account'
import Register from './features/user/Register'
import Welcome from './features/user/Welcome'
import MainJourney from './features/journey/MainJourney'
import JourneySegment from './features/journey/JourneySegment'
import JourneyFinisher from './features/journey/JourneyFinisher'
import ForgottenPassword from './features/user/ForgottenPassword'
import { getHasFinishedIntro } from './features/user/userSlice'
import routes from './app/routes'
import SpeedDialMenu from './features/journey/SpeedDialMenu'
import WelcomeDialog from './features/user/WelcomeDialog'
import FlashMessage from './features/flashmessage/FlashMessage'
import GDPR from './components/GDPR'

const App = () => {
  const location = useLocation()
  const { pathname } = location
  return (
    <div>
      <Route path={routes.intro}>
        {useSelector(getHasFinishedIntro) ? (
          <Redirect to={routes.resa} />
        ) : null}
      </Route>
      <FlashMessage />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

        <Route exact path={routes.home}>
          <HomeScreen />
        </Route>

        <Route path={routes.quiz}>
          <Quiz />
        </Route>

        <Route exact path={routes.registrera}>
          <Register />
        </Route>

        <Route exact path={routes.valkommen}>
          <Welcome />
        </Route>

        <Route path={routes.skapaVerktyg}>
          <Tool />
        </Route>

        <Route exact path={routes.valjReseLangd}>
          <JourneyLengthChooser />
        </Route>

        <Route exact path={routes.login}>
          <Login />
        </Route>

        <Route exact path={routes.konto}>
          <Account />
        </Route>

        <Route exact path={routes.glomtLosenord}>
          <ForgottenPassword />
        </Route>

        <Route exact path={routes.resa}>
          <Background image="mixed">
            <WelcomeDialog />
            <MainJourney />
          </Background>
        </Route>
        <Route path={`${routes.resa}/:segmentNumber`}>
          <JourneySegment />
        </Route>
        <Route exact path={routes.verktyg}>
          <br />
          <ToolList />
          <br />
        </Route>

        <Route exact path={routes.aktiviteter}>
          <Background image="mixed">
            <Activity />
          </Background>
        </Route>

        <Route exact path={routes.avslutning}>
          <Background>
            <JourneyFinisher />
          </Background>
        </Route>
        <Route exact path={routes.personuppgifter}>
          <GDPR />
        </Route>

        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <SpeedDialMenu />
    </div>
  )
}

export default App
