import React from 'react'
import clsx from 'clsx'
import { Button } from 'antd'
import styles from './IconButton.module.css'

const IconButton = ({
  color = 'black',
  size = 'middle',
  disabled = false,
  shape = 'circle-outline',
  ...props
}) => {
  return (
    <Button
      className={clsx(
        styles.iconButton,
        color === 'black' ? styles.blackIconButton : styles.whiteIconButton,
        disabled && styles.disabled
      )}
      shape={shape}
      size={size}
      disabled={disabled}
      {...props}
    ></Button>
  )
}

export default IconButton
